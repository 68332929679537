import { Entity } from 'modules/App/models';
import { DataTableSort } from 'modules/Ui/DataTable/DataTable.models';
import { Source } from 'types/source';

export type ProductSource = Source;

export interface Product extends Partial<Entity> {
  readonly name: string;
  readonly reference?: string | null;
  readonly description?: string;
  readonly taxKey: string;
  readonly buyingPrice?: number;
  readonly sellingPrice?: number;
  readonly discountPercentage?: number;
  readonly retailPrice?: number;
  readonly marginPercentage?: number;
  readonly source?: ProductSource;
  readonly lastSource?: ProductSource;
  readonly isRetailPriceFixedByUser?: boolean;
  readonly skuCode?: string | null;
  readonly availableStock?: number | null;
  readonly lastUsageInDocumentAt?: Date | null;
  readonly alarmStock?: number | null;
  readonly hasImage?: boolean;
  readonly imageVersion?: string;
}

export enum SortableFields {
  REFERENCE = 'reference',
  NAME = 'name',
  BUYING_PRICE = 'buyingPrice',
  SELLING_PRICE = 'sellingPrice',
  MARGIN_PERCENTAGE = 'marginPercentage',
  DISCOUNT_PERCENTAGE = 'discountPercentage',
  RETAIL_PRICE = 'retailPrice',
  LAST_USAGE_IN_DOCUMENTS_AT = 'lastUsageInDocumentAt',
  AVAILABLE_STOCK = 'availableStock',
  SKU_CODE = 'skuCode',
}

export type ProductSort = DataTableSort<Product, keyof Product>;

export interface ProductFilters {
  searchTerm?: string;
  sellingPriceFrom?: string;
  sellingPriceTo?: string;
  buyingPriceFrom?: string;
  buyingPriceTo?: string;
  retailPriceFrom?: string;
  retailPriceTo?: string;
  availableStock?: string;
}

export interface ProductResponse {
  data: Product;
}

export enum ImageSizesEnum {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  ORIGINAL = 'ORIGINAL',
}
