import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: { id: 'gofre.Documents.SortOutExpensesModal.title' },
  titleRecurrent: { id: 'gofre.Documents.SortOutExpensesModal.titleRecurrent' },
  confirmText: { id: 'gofre.common.action.accept' },
  cancel: { id: 'gofre.common.action.cancel' },
  success: { id: 'gofre.Documents.SortOutExpensesModal.notifications.success' },
  error: { id: 'gofre.Documents.SortOutExpensesModal.notifications.error' },
  zeroStateSubtitle: {
    id: 'gofre.Documents.SortOutExpensesModal.zeroState.subtitle',
  },
  zeroStateTitle: {
    id: 'gofre.Documents.SortOutExpensesModal.zeroState.title',
  },
});

export const expenseLedgerTexts = defineMessages({
  ware: { id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.ware' },
  supplies: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.supplies',
  },
  otherCompanies: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.otherCompanies',
  },
  otherExpenses: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.otherExpenses',
  },
  returns: { id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.returns' },
  renting: { id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.renting' },
  professionalServices: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.professionalServices',
  },
  logistics: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.logistics',
  },
  insurance: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.insurance',
  },
  bankingExpenses: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.bankingExpenses',
  },
  advertising: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.advertising',
  },
  generalSupplies: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.generalSupplies',
  },
  otherServices: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.otherServices',
  },
  salary: { id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.salary' },
  socialTaxes: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.socialSecurityTaxes',
  },
  socialExpenses: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.socialExpenses',
  },
  bankDebtInterest: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.bankDebtInterest',
  },
  tangibleFixedEquipment: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.tangibleFixedEquipment',
  },
  amortizationIntangibleFixedAssets: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.amortizationIntangibleFixedAssets',
  },
  amortizationPropertyAndEquipment: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.amortizationPropertyAndEquipment',
  },
  amortizationInvestmentProperty: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.amortizationInvestmentProperty',
  },
  currentTax: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.currentTax',
  },
  deferredTax: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.deferredTax',
  },
  exceptionalExpenses: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.exceptionalExpenses',
  },
  indemnifications: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.indemnifications',
  },
  interestDebtsOtherCompanies: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.interestDebtsOtherCompanies',
  },
  otherFinancialExpenses: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.otherFinancialExpenses',
  },
  otherTaxes: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.otherTaxes',
  },
  rawMaterials: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.rawMaterials',
  },
  repairsAndMaintenance: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.repairsAndMaintenance',
  },
  researchExpenditures: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.researchExpenditures',
  },
  variationsInventoriesOtherSupplies: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.variationsInventoriesOtherSupplies',
  },
  variationsMerchadiseInventories: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.variationsMerchadiseInventories',
  },
  variationsRawMaterialInventories: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.variationsRawMaterialInventories',
  },
  rawMaterialInventories: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.variations.rawMaterialInventories',
  },
  noCategory: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.noCategory',
  },
  noClasification: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.noClasification',
  },
  owed: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.owed',
  },
});

export default messages;
