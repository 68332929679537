import { FC } from 'react';
import TagsManager from 'react-tagsinput';

import omit from 'lodash/omit';
import { layout, LayoutProps, system } from 'styled-system';

import { min } from 'modules/Theme/breakpoints';
import styled from 'modules/Theme/styled-components';
import { FormikFieldProps } from 'modules/Ui/Formik/FormikFieldProps';

import Description from '../Commons/Description';
import InputWrapper from '../Commons/InputWrapper';
import Label from '../Commons/Label';
import LabelText from '../Commons/LabelText';
import TagsInput from './Tags/TagsInput';

export interface FieldProps extends FormikFieldProps, LayoutProps {
  onChangeHandler: TagsManager.ReactTagsInputProps<string>['onChange'];
  inputValue: string;
  onChangeInput: (value: string) => void;
  error?: string;
  onFocusHandler: () => void;
  gridColumnStart?: {} | string;
  gridColumnEnd?: {} | string;
}

const StyledLabel = styled(Label)`
  ${layout}
  ${system({
    gridColumnStart: {
      property: 'gridColumnStart',
      transform: (value) => `${value}`,
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      transform: (value) => `${value}`,
    },
  })}
`;

const StyledTagsInput = styled(TagsInput)`
  @media (hover: hover) {
    &:hover {
      cursor: text;
    }
  }

  .react-tagsinput-input {
    input {
      border: none;
      font-size: ${(props) => props.theme.lineHeights[16]};
      line-height: ${(props) => props.theme.lineHeights[30]};
      outline: none;
      width: 358px;
    }
  }

  > span {
    display: block;
    padding: 2px 8px 6px;
    @media ${min(768)} {
      display: flex;
      flex-wrap: wrap;
    }

    /*Delete the tag introduction input when painting 3 pills */
    button:nth-last-child(n+4) ~ .react-tagsinput-input {
      display: none !important;
    }
  }
`;

const TagsField: FC<FieldProps> = ({
  helpText,
  icon,
  id,
  label,
  onChangeHandler,
  placeholder,
  value,
  onChangeInput,
  inputValue,
  error,
  onFocusHandler,
  ...rest
}) => {
  // Props passed down to text input
  const inputProps = {
    placeholder,
    id,
    name: id,
  };

  return (
    <StyledLabel
      {...{
        id,
        ...omit(rest, ['data-testid']),
        onFocus: onFocusHandler,
        ...rest,
      }}
    >
      <LabelText>{label}</LabelText>
      <InputWrapper
        aria-live="polite"
        aria-relevant="additions"
        className="react-tagsinput-wrapper"
        height="auto"
        role="alert"
      >
        <StyledTagsInput
          {...{
            value,
            onChange: onChangeHandler,
            inputProps,
            onChangeInput,
            inputValue: inputValue || '',
            ...rest,
          }}
        />
      </InputWrapper>
      <Description id={`description-${id}`} hasError={!!error}>
        {error || helpText}
      </Description>
    </StyledLabel>
  );
};

export default TagsField;
