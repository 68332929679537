import { defineMessages } from 'react-intl';

export default defineMessages({
  okMessage: { id: 'gofre.Business.Update.okMessage' },
  buttonContinue: { id: 'gofre.common.action.continue' },
  country: { id: 'gofre.Manager.Create.Form.country' },
  companySize: { id: 'gofre.Manager.Create.Form.company-size.name' },
  countryPlaceholder: { id: 'gofre.Manager.Create.Form.country.placeholder' },
  dataLegend: { id: 'gofre.Manager.Create.Form.data-legend' },
  name: { id: 'gofre.Manager.Create.Form.fiscal-name' },
  namePlaceholder: { id: 'gofre.Manager.Create.Form.fiscal-name.placeholder' },
  nif: { id: 'gofre.Manager.Create.Form.vat-number' },
  nifPlaceholder: { id: 'gofre.Manager.Create.Form.vat-number.placeholder' },
  phone: { id: 'gofre.Manager.Create.Form.phone' },
  phonePlaceholder: { id: 'gofre.Manager.Create.Form.phone.placeholder' },
  province: { id: 'gofre.Manager.Create.Form.province' },
  provincePlaceholder: {
    id: 'gofre.Manager.Create.Form.province.placeholder',
  },
  sizeSmall: { id: 'gofre.Manager.Create.Form.company-size.small' },
  sizeMedium: { id: 'gofre.Manager.Create.Form.company-size.medium' },
  sizeBig: { id: 'gofre.Manager.Create.Form.company-size.big' },
  sizeNotDefined: { id: 'gofre.Manager.Create.Form.company-size.not-defined' },
  software: { id: 'gofre.Manager.Create.Form.software.title' },
  softwarePlaceholder: {
    id: 'gofre.Manager.Create.Form.software-placeholder.title',
  },
  softwareOtherOption: { id: 'gofre.Manager.Create.Form.software-other.title' },
  softwareOtherOptionPlaceholder: {
    id: 'gofre.Manager.Create.Form.software-other-placeholder.title',
  },
  softwareDiamacon: { id: 'gofre.Manager.Create.Form.software.diamacon' },
  softwareA3: { id: 'gofre.Manager.Create.Form.software.a3' },
  softwareSageContaplus: {
    id: 'gofre.Manager.Create.Form.software.sage-contaplus',
  },
  softwareSageDespachos: {
    id: 'gofre.Manager.Create.Form.software.sage-despachos',
  },
  softwareContasol: {
    id: 'gofre.Manager.Create.Form.software.contasol',
  },
  softwareAplifisa: {
    id: 'gofre.Manager.Create.Form.software.aplifisa',
  },
  softwareExcel: { id: 'gofre.Manager.Create.Form.software.excel' },
  softwareOther: { id: 'gofre.Manager.Create.Form.software.other' },
  title: { id: 'gofre.Manager.Create.Form.title' },
  subtitle: { id: 'gofre.Manager.Create.Form.subtitle' },
  logout: { id: 'gofre.Manager.logout' },
  contactData: { id: 'gofre.Manager.Form.contact-data' },
  email: { id: 'gofre.common.label.email' },
  webPage: { id: 'gofre.Manager.Form.web-page' },
  webPagePlaceholder: { id: 'gofre.Manager.Form.web-page-placeholder' },
  description: { id: 'gofre.Manager.Form.description' },
  descriptionHelpText: { id: 'gofre.Manager.Form.description-help-text' },
  descriptionPlaceholder: { id: 'gofre.Manager.Form.description-placeholder' },
  fiscalDirection: { id: 'gofre.Manager.Form.fiscal-direction' },
  postalAddress: { id: 'gofre.Manager.Form.postal-direction' },
  postalCode: { id: 'gofre.Manager.Form.postalCode' },
  city: { id: 'gofre.Manager.Form.city' },
  additionalData: { id: 'gofre.Manager.Form.additional-data' },
  companySizeShort: { id: 'gofre.Manager.Form.company-size-short' },
  softwareShort: { id: 'gofre.Manager.Form.software-short' },
  info: { id: 'gofre.Manager.info' },
});

export const formErrors = defineMessages({
  emailInvalid: { id: 'gofre.Contacts.Form.email.invalid' },
  submitError: { id: 'gofre.Manager.Create.Form.submit-error' },
  vatNumberInvalid: { id: 'gofre.common.validation.nif_invalid' },
});

export const tabs = defineMessages({
  customers: { id: 'gofre.Bookkeeper.List.Tabs.customers' },
  pending: { id: 'gofre.Bookkeeper.List.Tabs.pending' },
});

export const clientList = defineMessages({
  title: { id: 'gofre.Bookkeeper.Home.title' },
  titleDiamacon: { id: 'gofre.Bookkeeper.Home.titleDiamacon' },
});

export const zeroResults = defineMessages({
  title: { id: 'gofre.Bookkeeper.List.ZeroResults.title' },
  subtitle: { id: 'gofre.Bookkeeper.List.ZeroResults.subtitle' },
});

export const filter = defineMessages({
  title: { id: 'gofre.Bookkeeper.List.Filter.title' },
  dialogDescription: { id: 'gofre.Bookkeeper.List.Filter.dialog-description' },
  searchDesktop: { id: 'gofre.Bookkeeper.List.Filter.searchDesktop' },
  searchMobile: { id: 'gofre.Bookkeeper.List.Filter.searchMobile' },
  submit: { id: 'gofre.Bookkeeper.List.Filter.submit' },
  searchTermMinLength: { id: 'gofre.Bookkeeper.List.error.minLength' },
  lastExportToBeforeFromDate: {
    id: 'gofre.Bookkeeper.List.error.beforeFromDate',
  },
  exportDateTitle: { id: 'gofre.Bookkeeper.List.Filter.exportDate.title' },
  exportDateFrom: { id: 'gofre.Bookkeeper.List.Filter.exportDate.from' },
  exportDateTo: { id: 'gofre.Bookkeeper.List.Filter.exportDate.to' },
  lastExport: { id: 'gofre.common.label.last_documents' },
});

export const actions = defineMessages({
  downloadZip: { id: 'gofre.Bookkeeper.List.List.Actions.download-zip' },
  resendInvitation: {
    id: 'gofre.Bookkeeper.List.List.Actions.resend-invitation',
  },
  editInvitation: {
    id: 'gofre.Bookkeeper.List.List.Actions.edit-invitation',
  },
  cancelInvitation: {
    id: 'gofre.Bookkeeper.List.List.Actions.cancel-invitation',
  },
  loginAsBusiness: {
    id: 'gofre.Bookkeeper.List.List.Actions.login-as-business',
  },
  deleteClients: {
    id: 'gofre.Bookkeeper.List.List.Actions.delete-clients',
  },
});

export const zeroState = defineMessages({
  link: { id: 'gofre.Bookkeeper.customers.List.ZeroState.link' },
  subtitle: { id: 'gofre.Bookkeeper.customers.List.ZeroState.subtitle' },
  title: { id: 'gofre.Bookkeeper.customers.List.ZeroState.title' },
});

export const zeroStatePending = defineMessages({
  link: { id: 'gofre.Bookkeeper.customers.List.ZeroStatePending.link' },
  subtitle: { id: 'gofre.Bookkeeper.customers.List.ZeroStatePending.subtitle' },
  title: { id: 'gofre.Bookkeeper.customers.List.ZeroStatePending.title' },
});

export const listCustomerHeader = defineMessages({
  vatNumber: {
    id: 'gofre.common.label.nif',
  },
  customer: {
    id: 'gofre.Bookkeeper.Customers.List.List.Issued.Header.customer',
  },
  newDocuments: {
    id: 'gofre.Bookkeeper.Customers.List.List.Issued.Header.newDocuments',
  },
  lastDocumentDate: {
    id: 'gofre.Bookkeeper.Customers.List.List.Issued.Header.lastDocumentDate',
  },
  lastDocumentDateTablet: {
    id: 'gofre.Bookkeeper.Customers.List.List.Issued.Header.lastDocumentDate.tablet',
  },
  lastExportDate: {
    id: 'gofre.Bookkeeper.Customers.List.List.Issued.Header.lastExportDate',
  },
});

export const invitationCustomerHeader = defineMessages({
  vatNumber: {
    id: 'gofre.common.label.nif',
  },
  customer: {
    id: 'gofre.Bookkeeper.Customers.List.List.Invitation.Header.customer',
  },
  email: {
    id: 'gofre.Bookkeeper.Customers.List.List.Invitation.Header.email',
  },
});

export const notificationsMessages = defineMessages({
  downloadZipError: {
    id: 'gofre.Bookkeeper.Customer.List.Actions.download-zip.error',
  },
  error: { id: 'gofre.Bookkeeper.Customer.List.error' },
});

export const resendInvitationModal = defineMessages({
  confirm: { id: 'gofre.Bookkeeper.Customer.List.Resend.Modal.confirm' },
  text: { id: 'gofre.Bookkeeper.Customer.List.Resend.Modal.text' },
  title: { id: 'gofre.Bookkeeper.Customer.List.Resend.Modal.title' },
});

export const pendingInvitationsNotifications = defineMessages({
  error: { id: 'gofre.Bookkeeper.PendingInvitations.List.error' },
});

export const bookkeeperRegister = defineMessages({
  title: { id: 'gofre.Bookkeeper.register.title' },
  text01: { id: 'gofre.Bookkeeper.register.text01' },
  text02: { id: 'gofre.Bookkeeper.register.text02' },
  linkBookkeeper: { id: 'gofre.Bookkeeper.register.link.bookkeeper' },
  linkUser: { id: 'gofre.Bookkeeper.register.link.user' },
  linkBusiness: { id: 'gofre.Bookkeeper.register.link.business' },
});
