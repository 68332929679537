import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { emptyContact } from 'modules/Contacts/models';
import { DocumentDefaultValues } from 'modules/DefaultValues/models/document-default-values';
import { NewDocumentForm } from 'modules/Documents/models/form';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';
import { DocumentSerie } from 'modules/Settings/models';

import { Document, DocumentStatus } from '../../models/document';
import { PersonType } from '../../models/personType';
import { CorrectiveCause } from '../../Rectify/models/correctiveCause';
import { createAdditionalExpense } from '../default-additionalExpense';
import { createDefaultConcept } from '../default-concept';
import { processPaymentMethodsFromDocument } from './helpers';
import processConcepts from './processConcepts';

const correctiveValues = ({
  document,
  series,
  isReceipt,
  savedDefaultValues,
  paymentMethods,
}: {
  document: Document;
  series: DocumentSerie[];
  isReceipt?: boolean;
  savedDefaultValues?: DocumentDefaultValues | null;
  paymentMethods?: CreatedPaymentMethod[];
}): NewDocumentForm => {
  return {
    hasAdditionalExpenses: !isEmpty(document.additionalExpenses),
    additionalExpenses: !isEmpty(document.additionalExpenses)
      ? document.additionalExpenses?.map((xs) => ({
          ...xs,
          expense: (xs.expense || 0) * -1,
        }))
      : [createAdditionalExpense()],
    creationDate: new Date(),
    dueDate: undefined,
    initDateTime: new Date(),
    concepts: !isEmpty(document.concepts)
      ? processConcepts(document.concepts).map((xs) => ({
          ...xs,
          unitPrice: (xs.unitPrice || 0) * -1,
          totalAmount: (xs.totalAmount || 0) * -1,
        }))
      : [createDefaultConcept(document.taxRegime || '')],
    comments: document.comments,
    currency: document.currency,
    showComments: !!document.comments,
    hasRetentions: !!document.totals?.retentionPercentage,
    hasSalesEqualizationTax: document.hasSalesEqualizationTax,
    reference: document.reference || '',
    retentionPercentage: document.totals?.retentionPercentage || 15,
    hasPaidAmount: !!document.totals?.paidAmount || false,
    paidAmount: (document.totals?.paidAmount || 0) * -1,
    serieId: series[0].id,
    serialCode: series[0].serialCode,
    code: series[0].lastNumber + 1,
    isDefaultDraft: document.status === DocumentStatus.DRAFT,
    status: DocumentStatus.ISSUED,
    templateId: document.templateId,
    correctedDocuments: [document.id],
    correctiveCause: isReceipt ? CorrectiveCause.R5 : CorrectiveCause.R1,
    operationDescription: document.operationDescription || '',
    operationDateTime: undefined,
    naturalPerson: document.naturalPerson,
    naturalPersonType: document.naturalPerson
      ? PersonType.NATURAL
      : PersonType.LEGAL,
    epigraphIAE: document.epigraphIAE,
    chargingCriterionIRPF: document.chargingCriterionIRPF,
    hasCustomIRPF: !!document.customIRPF,
    customIRPF: document.customIRPF,
    taxRegime: document.taxRegime,
    paymentMethods: processPaymentMethodsFromDocument({
      documentMethods: document.paymentMethodsAccepted,
      defaultMethods: paymentMethods,
      preserveContactMethods: true,
    }),
    ...(!isReceipt && {
      contact: document.contact || { ...emptyContact },
    }),
    showProductReference: savedDefaultValues?.showProductReference,
    showLinesWithoutTaxes: document.showLinesWithoutTaxes,
    requestId: uuidv4(),
  };
};

export default correctiveValues;
