import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog } from 'modules/Modals';

import Button from '../Form/Button';
import NavigationGuard from '../NavigationGuard/NavigtionGuard';
import Text from '../Text';
import { messages } from './messages';

interface NavigationConfirmProps {
  id: string;
  showModal: boolean;
  title?: string;
  text?: string;
  confirm?: string;
  cancel?: string;
}

const NavigationConfirm: FC<NavigationConfirmProps> = ({
  id,
  showModal,
  title,
  text,
  confirm,
  cancel,
}) => {
  const { t } = useTranslations();
  return (
    <NavigationGuard when={showModal}>
      {({ onCancel, onConfirm }) => {
        return (
          <Dialog
            id={id}
            title={title || t(messages.title)}
            onClose={() => onCancel()}
            buttonPrimary={
              <Button onClick={onConfirm} variant="secondary">
                {confirm || t(messages.confirm)}
              </Button>
            }
            buttonSecondary={
              <Button onClick={onCancel} variant="stroke">
                {cancel || t(messages.cancel)}
              </Button>
            }
          >
            <Text>{text || t(messages.text)}</Text>
          </Dialog>
        );
      }}
    </NavigationGuard>
  );
};

export default NavigationConfirm;
