import { FC } from 'react';

import IconSearch from 'modules/Ui/Icons/IconSearch';

interface Props {
  color?: string;
}

export const IconSearchList: FC<Props> = ({ color }) => {
  return <IconSearch color={color || 'gray500'} transform="scaleX(-1)" />;
};

export default IconSearchList;
