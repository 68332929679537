import { defineMessages } from 'react-intl';

import { DefineMessages } from 'modules/I18n/types';

const texts: DefineMessages = defineMessages({
  change: { id: 'gofre.Subscriptions.Plan.change' },
  closeSession: { id: 'gofre.common.action.logout' },
  goToHome: { id: 'gofre.menu.goHome' },
});

export default texts;
