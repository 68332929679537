import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  submit: { id: 'gofre.User.ChangePassword.submit' },
  cancel: { id: 'gofre.common.action.cancel' },
  title: { id: 'gofre.common.action.change_password' },
  rules: { id: 'gofre.User.ChangePassword.rules' },
  // Fields
  current: { id: 'gofre.User.ChangePassword.current' },
  new: { id: 'gofre.User.ChangePassword.new' },
  newConfirmation: { id: 'gofre.User.ChangePassword.new-confirmation' },
});

export const formErrors = defineMessages({
  changePasswordError: { id: 'gofre.Users.Detail.change-password.error' },
  genericError: { id: 'gofre.common.error.generic' },
  currentPassword: { id: 'gofre.User.ChangePassword.current.required' },
  newPassword: { id: 'gofre.User.ChangePassword.new.required' },
  newPasswordInvalid: {
    id: 'gofre.User.ChangePassword.new.invalid',
  },
  newPasswordConfirmation: {
    id: 'gofre.User.ChangePassword.new.confirmation.required',
  },
  newPasswordConfirmationInvalid: {
    id: 'gofre.User.ChangePassword.new.confirmation.invalid',
  },
});
