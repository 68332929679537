import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  text: { id: 'gofre.Form.AddPaymentReminderModal.text' },
  subtext: { id: 'gofre.Form.AddPaymentReminderModal.subtext' },
  title: { id: 'gofre.Form.AddPaymentReminderModal.title' },
  accept: { id: 'gofre.Form.AddPaymentReminderModal.accept' },
  cancel: { id: 'gofre.common.action.cancel' },
  info: { id: 'gofre.Form.AddPaymentReminderModal.info' },
  sendDate: { id: 'gofre.Form.AddPaymentReminderModal.sendDate' },
  sendDay: { id: 'gofre.Form.AddPaymentReminderModal.sendDay' },
  sendDays: { id: 'gofre.Form.AddPaymentReminderModal.sendDays' },
  expiration: { id: 'gofre.Form.AddPaymentReminderModal.expirationDays' },
  beforeAfterExpiration: {
    id: 'gofre.Form.AddPaymentReminderModal.beforeAfterExpiration',
  },
  beforeExpiration: {
    id: 'gofre.Form.AddPaymentReminderModal.beforeExpiration',
  },
  afterExpiration: {
    id: 'gofre.Form.AddPaymentReminderModal.afterExpiration',
  },
  ofTheExpiration: {
    id: 'gofre.Form.AddPaymentReminderModal.ofTheExpiration',
  },
  chooseTone: {
    id: 'gofre.Form.AddPaymentReminderModal.chooseTone',
  },
  friendlyContent: {
    id: 'gofre.Form.AddPaymentReminderModal.friendlyContent',
  },
  formalContent: {
    id: 'gofre.Form.AddPaymentReminderModal.formalContent',
  },
  angryContent: {
    id: 'gofre.Form.AddPaymentReminderModal.angryContent',
  },
  subject: { id: 'gofre.Form.AddPaymentReminderModal.subject' },
  required: { id: 'gofre.common.validation.required' },
  maxDays: { id: 'gofre.Form.AddPaymentReminderModal.maxDays' },
  requiredField: { id: 'gofre.common.validation.required_field' },
  toneFriendly: { id: 'gofre.Form.AddPaymentReminderModal.toneFriendly' },
  toneFormal: { id: 'gofre.Form.AddPaymentReminderModal.toneFormal' },
  toneAngry: { id: 'gofre.Form.AddPaymentReminderModal.toneAngry' },
  editPaymentReminderTitle: {
    id: 'gofre.Form.AddPaymentReminderModal.editPaymentReminderTitle',
  },
  acceptEdit: {
    id: 'gofre.common.action.save',
  },
});
