import { ReactNode, FC } from 'react';

import { SpaceProps } from 'styled-system';

import { IconAttachment } from 'modules/Ui/Icons';

import MainFileAttachment from './MainFileAttachment';

interface Props extends SpaceProps {
  clickable?: boolean;
  contentLength?: string;
  documentId?: string;
  fileName?: string;
  icon?: string | ReactNode;
  id?: string;
  isResponsive?: boolean;
  loading?: boolean;
  onRemove?: () => void;
  text?: string;
}

const FileAttachment: FC<Props> = ({
  documentId,
  clickable = false,
  isResponsive = false,
  fileName = '',
  contentLength = '',
  loading,
  text,
  icon = <IconAttachment color="brand500" />,
  onRemove,
  ...rest
}) => {
  return (
    <MainFileAttachment
      documentId={documentId}
      clickable={clickable}
      isResponsive={isResponsive}
      fileName={fileName}
      contentLength={contentLength}
      loading={loading}
      text={text}
      icon={icon}
      onRemove={onRemove}
      {...rest}
    />
  );
};
export default FileAttachment;
