import { ExpenseCategories } from 'modules/Documents/models/document';

export const normalizeExpenseCategoryParam = (
  category: ExpenseCategories | null
) => {
  if (category === null) {
    return undefined;
  }

  if (category === ExpenseCategories.Empty) {
    return null;
  }

  return category;
};
