import { defineMessages } from 'react-intl';

const messages = defineMessages({
  vatNumber: { id: 'gofre.common.label.nif' },
  buttonPrimary: { id: 'gofre.common.action.save' },
  buttonSecondary: { id: 'gofre.common.action.cancel' },
  text: { id: 'gofre.FixVatNumberModal.text' },
  title: { id: 'gofre.FixVatNumberModal.title' },
});

export const formErrors = defineMessages({
  vatNumberInvalid: {
    id: 'gofre.FixVatNumberModal.Form.errors.vat-number.invalid',
  },
});

export default messages;
