import { defineMessages } from 'react-intl';

const messages = defineMessages({
  new: { id: 'gofre.Documents.state.new' },
  accepted: { id: 'gofre.Quotes.state.accepted.gender' },
  draft: { id: 'gofre.common.label.draft' },
  due: { id: 'gofre.Documents.state.due' },
  corrected: { id: 'gofre.Documents.state.corrected' },
  canceled: { id: 'gofre.Documents.state.canceled' },
  delivered: { id: 'gofre.Documents.state.delivered' },
  issued: { id: 'gofre.common.label.issued' },
  noPaid: { id: 'gofre.Documents.state.noPaid' },
  paid: { id: 'gofre.Documents.state.paid' },
  partiallyPaid: { id: 'gofre.Documents.state.partiallyPaid' },
  paidExpense: { id: 'gofre.Documents.state.paidExpense' },
  partiallyPaidExpense: { id: 'gofre.Documents.state.partiallyPaidExpense' },
  pending: { id: 'gofre.common.label.pending' },
  received: { id: 'gofre.Documents.state.received' },
  rejected: { id: 'gofre.Quotes.state.rejected.gender' },
  submitted: { id: 'gofre.Documents.digial.state.submitted' },
  test: { id: 'gofre.Documents.status.test' },
  toReview: { id: 'gofre.Documents.digial.state.toReview' },
  void: { id: 'gofre.Documents.digial.state.void' },
  replaced: { id: 'gofre.Documents.digial.state.replaced' },
  invoiced: { id: 'gofre.Documents.status.invoiced.gender' },
  noInvoiced: { id: 'gofre.Documents.state.noInvoiced' },
  linked: { id: 'gofre.Documents.status.linked' },
  unlinked: { id: 'gofre.Documents.status.unlinked' },
});

export default messages;
