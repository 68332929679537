import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'ui.navigationConfirm.title',
  },
  text: {
    id: 'ui.navigationConfirm.text',
  },
  confirm: {
    id: 'gofre.common.action.continue',
  },
  cancel: {
    id: 'gofre.common.action.cancel',
  },
});
