import { defineMessages } from 'react-intl';

export const dialog = defineMessages({
  btnClose: { id: 'gofre.common.action.close_modal' },
});

export const deleteAccountDialog = defineMessages({
  btnClose: { id: 'gofre.Ui.Dialog.DeleteAccount.btnClose' },
  title: { id: 'gofre.Ui.Dialog.DeleteAccount.title' },
  titleMobile: { id: 'gofre.Ui.Dialog.DeleteAccount.titleMobile' },
});

export const dialogConfim = defineMessages({
  cancel: { id: 'gofre.common.action.cancel' },
  confirm: { id: 'gofre.common.action.continue' },
});

export const dialogInfo = defineMessages({
  confirm: { id: 'gofre.common.action.continue' },
});
