import styled from 'styled-components';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

interface SpinnerSvgProps extends IconByStringProps {}

const StyledSpinner = styled(IconByString)`
  .spinner--animation{
    transform-origin: center;
    animation: spinner--rotation ${({ animationDuration }) =>
      animationDuration && `${animationDuration}ms`} step-end infinite;
  }

  @keyframes spinner--rotation {
    8.3%  {transform: rotate(30deg)}
    16.6% {transform: rotate(60deg)}
    25%   {transform: rotate(90deg)}
    33.3% {transform: rotate(120deg)}
    41.6% {transform: rotate(150deg)}
    50%   {transform: rotate(180deg)}
    58.3% {transform: rotate(210deg)}
    66.6% {transform: rotate(240deg)}
    75%   {transform: rotate(270deg)}
    83.3% {transform: rotate(300deg)}
    91.6% {transform: rotate(330deg)}
    100%  {transform: rotate(360deg)}
  }
`;

const IconSpinnerBars = (props: SpinnerSvgProps) => {
  const { animationDuration = 750, fillColor, ...rest } = props;
  return (
    <StyledSpinner
      fillColor={fillColor || 'inherit'}
      animationDuration={animationDuration}
      {...rest}
    >
      <g className="spinner--animation">
        <path opacity="0.14" d="M11 1h2v5h-2z" />
        <path opacity="0.29" d="m16.634 1.974 1.732 1-2.5 4.33-1.732-1z" />
        <path opacity="0.43" d="m21.026 5.634 1 1.732-4.33 2.5-1-1.732z" />
        <path opacity="0.57" d="M23 11v2h-5v-2z" />
        <path opacity="0.71" d="m22.026 16.634-1 1.732-4.33-2.5 1-1.732z" />
        <path opacity="0.86" d="m18.366 21.026-1.732 1-2.5-4.33 1.732-1z" />
        <path d="M13 23h-2v-5h2z" />
      </g>
    </StyledSpinner>
  );
};

export default IconSpinnerBars;
