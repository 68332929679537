import { defineMessages } from 'react-intl';

export const listPaymentHeader = defineMessages({
  operationDate: { id: 'gofre.common.label.date' },
  account: { id: 'gofre.Payments.list.header.account' },
  contact: { id: 'gofre.Payments.list.header.contact' },
  noAccount: { id: 'gofre.Payments.list.header.noAccount' },
  documents: { id: 'gofre.Payments.list.header.documents' },
  amount: { id: 'gofre.Payments.list.header.amount' },
  paymentType: { id: 'gofre.Payments.list.header.paymentType' },
  receipt: { id: 'gofre.common.label.cash_register_ticket' },
  paymentMethod: { id: 'gofre.Payments.list.header.paymentMethod' },
  deletedContact: { id: 'gofre.Payments.list.header.deletedContact' },
});

export const listPaymentActions = defineMessages({
  edit: { id: 'gofre.common.action.edit' },
  delete: { id: 'gofre.common.action.delete' },
  downloadExcel: { id: 'gofre.Payments.list.actions.downloadExcel' },
  abbrDownloadExcel: { id: 'gofre.common.label.xls' },
});

export const zeroResults = defineMessages({
  title: { id: 'gofre.Payments.list.zeroResults.title' },
  description: { id: 'gofre.Payments.list.zeroResults.description' },
  subtitle: { id: 'gofre.Payments.list.zeroResults.subtitle' },
});

export const filter = defineMessages({
  dialogDescription: { id: 'gofre.Payments.list.filter.dialogDescription' },
  button: { id: 'gofre.common.action.filter' },
  dateFromLegend: { id: 'gofre.Payments.List.Filter.date-from' },
  dateToLegend: { id: 'gofre.Payments.List.Filter.date-to' },
  minAmountEUR: {
    id: 'gofre.common.label.min_amount',
  },
  minAmountWithoutCurrency: {
    id: 'gofre.common.label.min_amount',
  },
  maxAmountEUR: {
    id: 'gofre.common.label.max_amount',
  },
  maxAmountWithoutCurrency: {
    id: 'gofre.common.label.max_amount',
  },
  amountPlaceholder: { id: 'gofre.Payments.List.Filter.amountPlaceholder' },
  completed: { id: 'gofre.Payments.List.Filter.status.completed' },
  partial: { id: 'gofre.Payments.List.Filter.status.partial' },
  typeLabel: { id: 'gofre.Payments.List.Filter.status.typeLabel' },
  selectPaidPlaceholder: {
    id: 'gofre.common.action.select',
  },
  legend: { id: 'gofre.common.action.filter' },
  linked: { id: 'gofre.Payments.List.Filter.linked' },
  notLinked: { id: 'gofre.common.action.no' },
  linkedLabel: { id: 'gofre.Payments.List.Filter.linkedLabel' },
  searchTermMinLength: { id: 'gofre.Payments.List.Filter.searchTermMinLength' },
  issueDateToBeforeFromDate: {
    id: 'gofre.Payments.List.Filter.issueDateToBeforeFromDate',
  },
  minAmountBiggerThanMaxAmount: {
    id: 'gofre.Payments.List.Filter.minAmountBiggerThanMaxAmount',
  },
});

export const notificationsMessages = defineMessages({
  error: { id: 'gofre.Payments.list.notifications.error' },
  downloadExcelError: {
    id: 'gofre.Payments.list.notifications.downloadExcelError',
  },
});

export const zeroStatePayments = defineMessages({
  title: { id: 'gofre.Payments.zeroState.title' },
  subtitle: { id: 'gofre.Payments.zeroState.subtitle' },
  buttonAdd: { id: 'gofre.Payments.list.buttonAdd' },
  linkAdd: { id: 'gofre.Payments.list.linkAdd' },
});

export const tags = defineMessages({
  delete: { id: 'gofre.Payments.List.Tag.delete' },
  paid: { id: 'gofre.Payments.List.Tag.paid' },
  partiallyPaid: { id: 'gofre.Payments.List.Tag.partiallyPaid' },
  linked: { id: 'gofre.Payments.List.Tag.linked' },
  notLinked: { id: 'gofre.Payments.List.Tag.notLinked' },
  dateFrom: { id: 'gofre.Payments.List.Tag.dateFrom' },
  dateTo: { id: 'gofre.Payments.List.Tag.dateTo' },
  minAmount: { id: 'gofre.Payments.List.Tag.minAmount' },
  minAmountBai: { id: 'gofre.Payments.List.Tag.minAmountBai' },
  maxAmount: { id: 'gofre.Payments.List.Tag.maxAmount' },
  maxAmountBai: { id: 'gofre.Payments.List.Tag.maxAmountBai' },
});
