import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'gofre.Bai.FixVatNumberModal.title' },
  warningAL: { id: 'gofre.Bai.FixVatNumberModal.warningAL' },
  warningBI: { id: 'gofre.Bai.FixVatNumberModal.warningBI' },
  warningGU: { id: 'gofre.Bai.FixVatNumberModal.warningGU' },
  submit: { id: 'gofre.common.action.save' },
  cancel: { id: 'gofre.common.action.cancel' },
  vatNumber: { id: 'gofre.Bai.FixVatNumberModal.vatNumber' },
  vatNumberHelpText: { id: 'gofre.Bai.FixVatNumberModal.vatNumberHelpText' },
  updateVatNumberErrorShort: {
    id: 'gofre.Business.Update.duplicate-nif-error-short',
  },
});

export const formErrors = defineMessages({
  vatNumberInvalid: { id: 'gofre.BaiFixVatNumberModal.Form.vatNumberInvalid' },
});
