import { defineMessages } from 'react-intl';

export default defineMessages({
  noMatches: {
    id: 'gofre.Settings.CreateTemplateDialog.no-matches',
  },
  loadingTemplates: {
    id: 'gofre.Settings.CreateTemplateDialog.loading',
  },
  templateLabel: {
    id: 'gofre.Settings.SearchTemplateInput.label',
  },
  templateLabelTablet: {
    id: 'gofre.Settings.SearchTemplateInput.label.tablet',
  },
  optionLabelAddTemplate: {
    id: 'gofre.Settings.SearchTemplateInput.option-label.add-contact',
  },
  templateName: {
    id: 'gofre.Settings.Templates.template.name',
  },
});

export const changeTemplateDialog = defineMessages({
  title: {
    id: 'gofre.Settings.SearchTemplateInput.changeTemplateDialog.title',
  },
  subtitle: {
    id: 'gofre.Settings.SearchTemplateInput.changeTemplateDialog.subtitle',
  },
  text: {
    id: 'gofre.Settings.SearchTemplateInput.changeTemplateDialog.text',
  },
  textInUse: {
    id: 'gofre.Settings.SearchTemplateInput.changeTemplateDialog.textInUse',
  },
  cancelText: {
    id: 'gofre.common.action.no_save',
  },
  confirmText: {
    id: 'gofre.common.action.save',
  },
});
