import { defineMessages } from 'react-intl';

export const formText = defineMessages({
  mandatory: { id: 'gofre.common.validation.mandatory_field' },
  mandatoryFields: {
    id: 'gofre.common.validation.mandatory_fields',
  },
  required: {
    id: 'gofre.common.validation.required_field',
  },
  maxAmount: {
    id: 'gofre.Form.field.maxAmount',
  },
  recaptchaRequired: { id: 'gofre.Documents.Send.error.recaptcha' },
});
