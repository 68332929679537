import { getConfig } from 'modules/App/config';
import addFieldsQuery from 'utils/query/addFieldsQuery';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import buildFiltersQuery from '../../helpers/buildFiltersQuery';
import { Contact, ContactSort, ContactType } from '../../models';

export default async function fetchContacts({
  page,
  pageSize,
  sortBy,
  searchTerm,
  clientType,
  withDocumentAmounts,
  onlyDebtors,
  from,
  to,
  minAmount,
  maxAmount,
  fields,
  customSearchFields,
  excludeEmptyVatNumber,
}: {
  page?: number;
  pageSize?: number;
  sortBy?: ContactSort;
  searchTerm?: string;
  clientType?: ContactType;
  readyToInvoice?: boolean;
  withDocumentAmounts?: boolean;
  onlyDebtors?: boolean;
  from?: Date;
  to?: Date;
  minAmount?: number | string;
  maxAmount?: number | string;
  fields?: string[];
  customSearchFields?: string[];
  excludeEmptyVatNumber?: boolean;
}) {
  const endpoint = withDocumentAmounts
    ? (await getConfig()).apiContactsWithAmountsService
    : (await getConfig()).apiContactsService;

  let query = buildTortillaBaseQuery({
    page,
    pageSize,
    sort: [
      {
        field: sortBy?.field,
        order: sortBy?.order,
      },
    ],
  });

  query += buildFiltersQuery(
    {
      clientType,
      searchTerm,
      onlyDebtors,
      from,
      to,
      minAmount,
      maxAmount,
      customSearchFields,
      excludeEmptyVatNumber,
    },
    withDocumentAmounts
  );

  if (fields?.length) {
    query += addFieldsQuery(fields);
  }

  const response = await request<any>(`${endpoint}?${query}`, {
    method: 'GET',
  });
  return response?.data?.data as { items: Contact[]; count: number };
}
