import { defineMessages } from 'react-intl';

import { DefineMessages } from 'modules/I18n/types';

export default defineMessages({
  bookkeeperEmail: { id: 'gofre.common.label.gestorias_billin_eu' },
  contactHeading: { id: 'gofre.Help.Contact.heading' },
  contactText: { id: 'gofre.Help.Contact.text' },
  email: { id: 'gofre.Help.Contact.Email.email' },
  emailHeading: { id: 'gofre.Help.Contact.Email.heading' },
  header: { id: 'gofre.Ui.Header.title.help' },
  phone: { id: 'gofre.common.billin.91_831_88_83' },
  phoneFridaytoSunday: { id: 'gofre.Help.Contact.Phone.FtoS' },
  phoneHeading: { id: 'gofre.Help.Contact.Phone.heading' },
  phoneMondaytoThursday: { id: 'gofre.Help.Contact.Phone.MtoT' },
  suggestions: { id: 'gofre.Help.Actions.suggestions' },
  tabTitle: { id: 'gofre.Help.Tabs.Regular.title' },
  tutorials: { id: 'gofre.Help.Actions.tutorials' },
  tutorialsLink: { id: 'gofre.Help.Actions.tutorials.link' },
});

export const messages: DefineMessages = defineMessages({
  email: { id: 'gofre.common.billin.hola_billin_eu' },
  emailREGULAR: { id: 'gofre.common.billin.hola_billin_eu' },
  emailBOOKKEEPER: { id: 'gofre.common.label.gestorias_billin_eu' },
});
