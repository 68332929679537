import { defineMessages } from 'react-intl';

export default defineMessages({
  addBusinessButton: { id: 'gofre.Business.Create.Form.add-business-button' },
  address: { id: 'gofre.Business.Create.Form.address' },
  addressLegend: { id: 'gofre.Business.Create.Form.address-legend' },
  addressPlaceholder: { id: 'gofre.Business.Create.Form.address.placeholder' },
  baiDisclaimer: { id: 'gofre.Business.Create.Form.address.bai.disclaimer' },
  baiQuestion: { id: 'gofre.Business.Create.Form.address.bai.question' },
  baiRadioAlava: { id: 'gofre.Business.Create.Form.address.bai.radioAlava' },
  baiRadioGuipuzkoa: {
    id: 'gofre.Business.Create.Form.address.bai.radioGuipuzkoa',
  },
  baiRadioVizcaya: {
    id: 'gofre.Business.Create.Form.address.bai.radioVizcaya',
  },
  baiRadioNoAction: {
    id: 'gofre.Business.Create.Form.address.bai.RadioNoAction',
  },
  infoText: { id: 'gofre.Business.Create.Form.address.bai.infoText' },
  cancelButton: { id: 'gofre.common.action.cancel' },
  city: { id: 'gofre.Business.Create.Form.city' },
  cityPlaceholder: { id: 'gofre.Business.Create.Form.city.placeholder' },
  country: { id: 'gofre.Business.Create.Form.country' },
  countryPlaceholder: { id: 'gofre.Business.Create.Form.country.placeholder' },
  dataLegend: { id: 'gofre.Business.Create.Form.data-legend' },
  delete: { id: 'gofre.Business.Update.deleteButton' },
  name: { id: 'gofre.Business.Create.Form.fiscal-name' },
  namePlaceholder: { id: 'gofre.Business.Create.Form.fiscal-name.placeholder' },
  nif: { id: 'gofre.Business.Create.Form.vat-number' },
  nifPlaceholder: { id: 'gofre.Business.Create.Form.vat-number.placeholder' },
  phone: { id: 'gofre.Business.Create.Form.phone' },
  phonePlaceholder: { id: 'gofre.Business.Create.Form.phone.placeholder' },
  province: { id: 'gofre.Business.Create.Form.province' },
  provincePlaceholder: {
    id: 'gofre.Business.Create.Form.province.placeholder',
  },
  postalCode: { id: 'gofre.Business.Create.Form.postalCode' },
  postalCodePlaceholder: {
    id: 'gofre.Business.Create.Form.postalCode.placeholder',
  },
  ariaLink: { id: 'gofre.Business.Create.Form.bai.aria-action' },
  baiDisabledMessage: {
    id: 'gofre.Business.Create.Form.bai.disabled-message',
  },
});

export const formErrors = defineMessages({
  postalCodeNumeric: { id: 'gofre.Business.Create.Form.postal-code.numeric' },
  vatNumberInvalid: { id: 'gofre.common.validation.nif_invalid' },
  postalCodeInvalid: { id: 'gofre.Business.Create.Form.postal-code.invalid' },
});

export const modal = defineMessages({
  title: { id: 'gofre.Business.Change.title' },
  confirm: { id: 'gofre.Business.Change.confirm' },
  cancel: { id: 'gofre.common.action.cancel' },
});

export const changeBusinessDialog = defineMessages({
  title: { id: 'gofre.Business.changeBusinessDialog.title' },
  subtitle: { id: 'gofre.Business.changeBusinessDialog.subtitle' },
  text01: { id: 'gofre.Business.changeBusinessDialog.text01' },
  text02: { id: 'gofre.Business.changeBusinessDialog.text02' },
  confirm: { id: 'gofre.common.action.save' },
  cancel: { id: 'gofre.common.action.cancel' },
});
