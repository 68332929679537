import { useQuery } from 'react-query';

import { CONTACTS_QUERY } from 'modules/App/queries/queries';
import { DataTableSortOrder } from 'modules/Ui/DataTable/DataTable.models';
import CacheTimes from 'utils/query/cacheTimes';

import fetchContacts from '../List/services/fetchContacts';
import { ContactType } from '../models';

interface UseLastUsedContactsParams {
  searchTerm: string;
  pageSize: number;
  clientType?: ContactType;
  customSearchFields?: string[];
  excludeEmptyVatNumber?: boolean;
}

const useLastUsedContacts = ({
  searchTerm,
  pageSize,
  clientType = ContactType.CUSTOMER,
  customSearchFields,
  excludeEmptyVatNumber,
}: UseLastUsedContactsParams) => {
  const { isLoading, data } = useQuery(
    [
      CONTACTS_QUERY,
      {
        searchTerm,
        pageSize,
        clientType,
        customSearchFields,
        excludeEmptyVatNumber,
      },
    ],

    () =>
      fetchContacts({
        page: 0,
        pageSize,
        searchTerm,
        customSearchFields,
        clientType,
        excludeEmptyVatNumber,
        sortBy: {
          order: DataTableSortOrder.DESC,
          field: 'lastUsageAt',
        },
      }),
    {
      refetchOnMount: false,
      staleTime: CacheTimes.FIFTEEN_MINUTES,
    }
  );

  return { isLoading, contacts: data?.items };
};

export default useLastUsedContacts;
