import { defineMessages } from 'react-intl';

export const progress = defineMessages({
  issuing: { id: 'gofre.Documents.ElectronicSubmission.issuing' },
  result: { id: 'gofre.Documents.ElectronicSubmission.result' },
  resultTest: { id: 'gofre.Documents.ElectronicSubmission.resultTest' },
  generating: { id: 'gofre.Documents.ElectronicSubmission.generating' },
  sending: { id: 'gofre.Documents.ElectronicSubmission.sending' },
  sendingTitle: { id: 'gofre.Documents.ElectronicSubmission.sending-title' },
  sendingDescription: {
    id: 'gofre.Documents.ElectronicSubmission.sending-description',
  },
  sendingTestTitle: {
    id: 'gofre.Documents.ElectronicSubmission.sending-test-title',
  },
  sendingTestDescription: {
    id: 'gofre.Documents.ElectronicSubmission.sending-test-description',
  },
  sendingVoidTitle: {
    id: 'gofre.Documents.ElectronicSubmission.sending-void-title',
  },
  sendingFixTitle: {
    id: 'gofre.Documents.ElectronicSubmission.sending-fix-title',
  },
  sendingVoidDescription: {
    id: 'gofre.Documents.ElectronicSubmission.sending-void-description',
  },
  sendingFixDescription: {
    id: 'gofre.Documents.ElectronicSubmission.sending-fix-description',
  },
});

export const resume = defineMessages({
  title: { id: 'gofre.Documents.ElectronicSubmission.title' },
  issuerTitle: { id: 'gofre.Documents.ElectronicSubmission.issuer.title' },
  customerTitle: { id: 'gofre.Documents.ElectronicSubmission.customer.title' },
  totalTitle: { id: 'gofre.Documents.ElectronicSubmission.total.title' },
  documentTypeTitle: {
    id: 'gofre.Documents.ElectronicSubmission.documentType.title',
  },
  documentTypeCorrective: {
    id: 'gofre.Documents.ElectronicSubmission.documentType.corrective',
  },
  documentTypeComplete: {
    id: 'gofre.Documents.ElectronicSubmission.documentType.completed',
  },
  documentTypeTest: {
    id: 'gofre.Documents.ElectronicSubmission.documentType.test',
  },
  documentCodeTitle: {
    id: 'gofre.Documents.ElectronicSubmission.documentCode.title',
  },
  issuedDateTitle: {
    id: 'gofre.Documents.ElectronicSubmission.issuedDate.title',
  },
  voidDateTitle: {
    id: 'gofre.Documents.ElectronicSubmission.voidDate.title',
  },
  errorTitle: {
    id: 'gofre.Documents.ElectronicSubmission.error.title',
  },
  documentStateTitle: {
    id: 'gofre.Documents.ElectronicSubmission.documentState.title',
  },
  receiptDescription: {
    id: 'gofre.Documents.ElectronicSubmission.receipt.description',
  },
  documentTypeSimplified: {
    id: 'gofre.Documents.ElectronicSubmission.documentType.simplified',
  },
});

export const actionMessages = defineMessages({
  open: { id: 'gofre.Documents.ElectronicSubmission.actions.open' },
  downloadPdf: {
    id: 'gofre.Documents.ElectronicSubmission.actions.downloadPdf',
  },
  send: { id: 'gofre.Documents.ElectronicSubmission.actions.send' },
  create: { id: 'gofre.Documents.ElectronicSubmission.actions.create' },
  copy: { id: 'gofre.Documents.ElectronicSubmission.actions.copy' },
  list: { id: 'gofre.Documents.ElectronicSubmission.actions.list' },
  home: { id: 'gofre.Documents.ElectronicSubmission.actions.home' },
  void: { id: 'gofre.Documents.ElectronicSubmission.actions.void' },
  edit: { id: 'gofre.Documents.ElectronicSubmission.actions.edit' },
  delete: { id: 'gofre.common.action.delete' },
  fix: { id: 'gofre.Documents.ElectronicSubmission.actions.fix' },
});

export const digitalStatusTag = defineMessages({
  presented: { id: 'gofre.Documents.ElectronicSubmission.tag.presented' },
  presentedWithErrors: {
    id: 'gofre.Documents.ElectronicSubmission.tag.presentedWithErrors',
  },
  pending: { id: 'gofre.common.label.pending' },
  rejected: { id: 'gofre.Documents.ElectronicSubmission.tag.rejected' },
  presentedWithErrorsTooltip: {
    id: 'gofre.Documents.ElectronicSubmission.tag.presentedWithErrors-tooltip',
  },
});

export const confirmBaiSubmissionModalTexts = defineMessages({
  title: { id: 'gofre.ConfirmBaiSubmissionModal.title' },
  subtitle: { id: 'gofre.ConfirmBaiSubmissionModal.subtitle' },
  confirmText: { id: 'gofre.ConfirmBaiSubmissionModal.confirmText' },
  text: { id: 'gofre.ConfirmBaiSubmissionModal.text' },
  label: { id: 'gofre.ConfirmBaiSubmissionModal.label' },
});
