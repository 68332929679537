import { defineMessages } from 'react-intl';

export const actions = defineMessages({
  menuOpen: { id: 'gofre.Ui.Header.open-menu' },
  menuClose: { id: 'gofre.Ui.Header.close-menu' },
  submenuClose: { id: 'gofre.Ui.Header.close-submenu' },
});

export const title = defineMessages({
  invoices: { id: 'gofre.Ui.Header.title.invoices' },
  business: { id: 'gofre.Ui.Header.title.business' },
});

export const menu = defineMessages({
  goToHome: { id: 'gofre.menu.goHome' },
  home: { id: 'gofre.Ui.Header.Menu.home' },
  quotes: { id: 'gofre.Ui.Header.Menu.quotes' },
  business: { id: 'gofre.Ui.Header.Menu.business' },
  contacts: { id: 'gofre.Ui.Header.Menu.contacts' },
  documents: { id: 'gofre.Ui.Header.Menu.documents' },
  documentsReceived: { id: 'gofre.Ui.Header.Menu.documents.received' },
  documentsSent: { id: 'gofre.Ui.Header.Menu.documents.sent' },
  documentsTitle: { id: 'gofre.Ui.Header.Menu.documents.title' },
  expenses: { id: 'gofre.Ui.Header.Menu.expenses' },
  help: { id: 'gofre.Ui.Header.Menu.help' },
  notifications: { id: 'gofre.Ui.Header.Menu.notifications' },
  myAccount: { id: 'gofre.Ui.Header.Menu.myAccount' },
  logout: { id: 'gofre.common.action.logout' },
  myBookkeeper: { id: 'gofre.Ui.Header.Menu.my-bookkeeper' },
  plans: { id: 'gofre.Ui.Header.Menu.plans' },
  products: { id: 'gofre.Ui.Header.Menu.products' },
  settings: { id: 'gofre.Ui.Header.Menu.settings' },
  subscriptions: { id: 'gofre.Ui.Header.Menu.subscriptions' },
  users: { id: 'gofre.Ui.Header.Menu.users' },
  changeBusiness: { id: 'gofre.Ui.Header.Menu.changeBusiness' },
  goBackToBookkeeper: { id: 'gofre.Ui.Header.Menu.goBackToBookkeeper' },
  bookkeeperProfile: { id: 'gofre.Ui.Header.Menu.bookkeeperProfile' },
  bookkeeperMyaccount: { id: 'gofre.Ui.Header.Menu.bookkeeperMyAccount' },
  languages: { id: 'gofre.Ui.Header.Menu.languages' },
  integrations: { id: 'gofre.integrations.title' },
  payments: { id: 'gofre.Ui.Header.Menu.payments' },
  submenuBanks: { id: 'gofre.Payments.Tabs.banks' },
  submenuRemittances: { id: 'gofre.Payments.Tabs.remittances' },
  submenuPayments: { id: 'gofre.Payments.Tabs.payments' },
});

export const menuNotifications = defineMessages({
  notificationsNumber: { id: 'gofre.Ui.Header.Menu.notificationsNumber' },
});

export const menuBookkeeper = defineMessages({
  home: { id: 'gofre.Ui.Header.MenuBookkeeper.Home' },
  clients: { id: 'gofre.Ui.Header.MenuBookkeeper.Clients' },
  profile: { id: 'gofre.Ui.Header.MenuBookkeeper.Profile' },
});
