import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonCancel: {
    id: 'gofre.common.action.cancel',
  },
  buttonCreate: {
    id: 'gofre.Documents.CreateContactDialog.dialog-button-accept',
  },
  buttonEdit: {
    id: 'gofre.common.action.save',
  },
  newClient: {
    id: 'gofre.Documents.CreateContactDialog.new-client',
  },
  editClient: {
    id: 'gofre.Documents.CreateContactDialog.edit-client',
  },
  updateClient: {
    id: 'gofre.Documents.CreateContactDialog.update-client',
  },
  vatOnlyForSpain: {
    id: 'gofre.Documents.CreateContactDialog.vatOnlyForSpain',
  },
  nifIvaHelpText: {
    id: 'gofre.Documents.CreateContactDialog.nifIvaHelpText',
  },
  passportHelpText: {
    id: 'gofre.Documents.CreateContactDialog.passportHelpText',
  },
  foreignOnlyHelpText: {
    id: 'gofre.Documents.CreateContactDialog.foreignOnlyHelpText',
  },
  nifHelpText: {
    id: 'gofre.Documents.CreateContactDialog.nifHelpText',
  },
});
