import { FC, useEffect, useState } from 'react';
import { Hidden } from 'react-grid-system';

import { IconSearchList } from 'modules/Contacts/SearchContactDialog/components/ContactList';
import ListDesktop, {
  ItemDesktop,
} from 'modules/Contacts/SearchContactDialog/components/ContactList/ListDesktop';
import { ExpenseCategories } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  MODAL_WIDTH_SMALL,
  MODAL_WIDTH_MEDIUM,
  Dialog,
  useCloseModal,
} from 'modules/Modals';
import { Button, Box, Field, ZeroResults } from 'modules/Ui';

import useExpenseLedger, { Option } from './hooks/useExpenseLedger';
import messages from './messages';

export interface SortOutExpensesModalProps {
  onConfirm: () => Promise<void>;
  setOptionSelected: (option: Option | undefined) => void;
  optionSelected: Option | undefined;
  isRecurrent?: boolean;
  loading: boolean;
}

function mapExpensensDesktop(options: Option[]): ItemDesktop[] {
  return options.map(({ id, label }) => ({
    id: id as string,
    column1: label,
  }));
}

const SortOutExpensesModal: FC<SortOutExpensesModalProps> = ({
  onConfirm,
  setOptionSelected,
  optionSelected,
  isRecurrent,
  loading,
}) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const initialOptions = useExpenseLedger({
    simple: false,
  });

  const [items, setItems] = useState<ItemDesktop[]>([]);
  const [options, setOptions] = useState<Option[]>(initialOptions);

  useEffect(() => {
    setItems(mapExpensensDesktop(options));
  }, [options]);

  const [searchTerm, setSearchTerm] = useState('');

  const handleOnChange = (e: any) => {
    setSearchTerm(e.target.value);
    const filteredOptions = initialOptions.filter((item) => {
      return item.label.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setOptions(filteredOptions);
  };

  const zeroSearchResults = !items.length && !loading;

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Dialog
      type="fullScreen"
      title={isRecurrent ? t(messages.titleRecurrent) : t(messages.title)}
      buttonPrimary={
        <Button
          data-testid="sort-out-expenses-submit"
          variant="secondary"
          onClick={onConfirm}
          disabled={zeroSearchResults}
        >
          {t(messages.confirmText)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button
            data-testid="sort-out-expenses-cancel"
            onClick={handleCancel}
            variant="stroke"
          >
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
      content={
        <Box
          aria-labelledby="expenses-title"
          display={{ _: 'grid', sm: 'initial' }}
          gridTemplateRows={{ _: 'auto 1fr', sm: 'initial' }}
          height={{ _: '100%', sm: 'initial' }}
          role="radiogroup"
          rowGap={{ _: '24px', sm: 'initial' }}
        >
          <Field
            icon={<IconSearchList />}
            id="searchCategory"
            label="Tipo de gasto"
            marginTop={{ _: '4px', sm: 'initial' }}
            name="searchCategory"
            onChange={handleOnChange}
            placeholder="Nombre de la categoría"
            value={searchTerm}
          />
          {zeroSearchResults && (
            <ZeroResults
              borderTop="none"
              margin="0"
              paddingBottom="40px"
              paddingTop="40px"
              subtitle={t(messages.zeroStateSubtitle)}
              title={t(messages.zeroStateTitle)}
            />
          )}
          <Box
            marginBottom={{ sm: '24px' }}
            maxHeight={{ sm: '295px' }}
            overflowY="auto"
          >
            <ListDesktop
              {...{
                items,
                onChange: (id: string) => {
                  const selectedOption = options.find(
                    (option) => option.id === id
                  );
                  setOptionSelected(selectedOption);
                },
                selectedItemId: optionSelected?.id ?? ExpenseCategories.Empty,
              }}
            />
          </Box>
        </Box>
      }
      id="sort-out-expenses"
      onClose={handleCancel}
      dialogWidth={{ sm: MODAL_WIDTH_MEDIUM, md: MODAL_WIDTH_SMALL }}
    />
  );
};

export default SortOutExpensesModal;
