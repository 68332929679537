import { defineMessages } from 'react-intl';

export default defineMessages({
  city: { id: 'gofre.Contacts.Form.city' },
  cityPlaceholder: { id: 'gofre.Contacts.Form.city-placeholder' },
  contactInfoTitle: { id: 'gofre.Contacts.Form.contact-info-title' },
  contactInvoicingInfoTitle: {
    id: 'gofre.Contacts.Form.contact-info-invoicing-title',
  },
  contactPaymentInfoTitle: {
    id: 'gofre.Contacts.Form.contact-info-payment-title',
  },
  contactPaymentInfoSubtitle: {
    id: 'gofre.Contacts.Form.contact-info-payment-subtitle',
  },
  contactPaymentInfoText: {
    id: 'gofre.Contacts.Form.contact-info-payment-text',
  },
  contactPaymentInfoButton: {
    id: 'gofre.Contacts.Form.contact-info-payment-button',
  },
  country: {
    id: 'gofre.Contacts.Form.country',
  },
  delete: { id: 'gofre.Contacts.Form.delete' },
  email: { id: 'gofre.common.label.email' },
  emailPlaceholder: { id: 'gofre.Contacts.Form.email-placeholder' },
  additionalEmailsPlaceholder: {
    id: 'gofre.Contacts.Form.additional-emails-placeholder',
  },
  equivalenceSurcharge: { id: 'gofre.Contacts.Form.equivalenceSurcharge' },
  fiscalName: { id: 'gofre.Contacts.Form.fiscal-name' },
  additionalEmails: { id: 'gofre.Contacts.Form.additional-emails' },
  additionalEmailsInfo: { id: 'gofre.Contacts.Form.additional-emails-info' },
  fiscalNamePlaceholder: { id: 'gofre.Contacts.Form.fiscal-name-placeholder' },
  infoAdditionalTitle: { id: 'gofre.Contacts.Form.info.title' },
  infoContactTaxSubtitle: {
    id: 'gofre.Contacts.Form.info.contact-tax.subtitle',
  },
  infoContactTaxDescription: {
    id: 'gofre.Contacts.Form.info.contact-tax.description',
  },
  infoContactTypeSubtitle: {
    id: 'gofre.Contacts.Form.info.contact-type.subtitle',
  },
  infoContactTypeDescription: {
    id: 'gofre.Contacts.Form.info.contact-type.description',
  },
  infoEquivalenceSurchargeSubtitle: {
    id: 'gofre.Contacts.Form.info.contact-equivalent-subcharge.subtitle',
  },
  infoEquivalenceSurchargeDescription: {
    id: 'gofre.Contacts.Form.info.contact-equivalent-subcharge.description',
  },
  infoPaymentSubtitle: {
    id: 'gofre.Contacts.Form.info.contact-payment.subtitle',
  },
  infoPaymentDescription: {
    id: 'gofre.Contacts.Form.info.contact-payment.description',
  },
  phoneNumber: { id: 'gofre.Contacts.Form.phone-number' },
  phoneNumberPlaceholder: {
    id: 'gofre.Contacts.Form.phone-number-placeholder',
  },
  postalAddress: { id: 'gofre.Contacts.Form.postal-address' },
  postalAddressPlaceholder: {
    id: 'gofre.Contacts.Form.postal-address-placeholder',
  },
  postalCode: { id: 'gofre.Contacts.Form.postal-code' },
  postalCodePlaceholder: { id: 'gofre.Contacts.Form.postal-code-placeholder' },
  province: { id: 'gofre.Contacts.Form.province' },
  provinceState: { id: 'gofre.Contacts.Form.provinceState' },
  provincePlaceholder: { id: 'gofre.Contacts.Form.province-placeholder' },
  taxesToInvoice: { id: 'gofre.Contacts.Form.taxes-type' },
  title: { id: 'gofre.Contacts.Form.contact-info-title' },
  type: { id: 'gofre.Contacts.Form.contact-type' },
  vatNumber: { id: 'gofre.Contacts.Form.vat-number' },
  vatNumberType: { id: 'gofre.Contacts.Form.vat-number-type' },
  vatNumberPlaceholder: { id: 'gofre.Contacts.Form.vat-number-placeholder' },
  typeCustomer: {
    id: 'gofre.Contacts.typeCustomer',
  },
  typeProvider: {
    id: 'gofre.Contacts.typeProvider',
  },
  description: {
    id: 'gofre.Documents.DeleteSelectedContactModal.description',
  },
  taxRegimeForSpain: {
    id: 'gofre.Documents.CreateContactDialog.taxRegimeForSpain',
  },
  taxRegimeisPublicEntity: {
    id: 'gofre.Documents.CreateContactDialog.taxRegimeisPublicEntity',
  },
  taxExemptionCauseForIntracommunitary: {
    id: 'gofre.Documents.CreateContactDialog.taxExemptionCauseForIntracommunitary',
  },
  paymentMethodContactEdit: { id: 'gofre.common.action.edit' },
  paymentMethodContactDelete: {
    id: 'gofre.common.action.delete',
  },
});

export const dialogs = defineMessages({
  deletePaymentMethodTitle: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.title',
  },
  deletePaymentMethodSubtitle: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.subtitle',
  },
  deletePaymentMethodSubtitleBizum: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.subtitle-bizum',
  },
  deletePaymentMethodSubtitleCash: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.subtitle-cash',
  },
  deletePaymentMethodSubtitleCashOnDelivery: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.subtitle-cash-on-delivery',
  },
  deletePaymentMethodSubtitleDirectDebit: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.subtitle-direct-debit',
  },
  deletePaymentMethodSubtitleOther: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.subtitle-other',
  },
  deletePaymentMethodSubtitleTransfer: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.subtitle-transfer',
  },
  deletePaymentMethodText: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.text',
  },
  deletePaymentMethodCancel: {
    id: 'gofre.common.action.cancel',
  },
  deletePaymentMethodConfirm: {
    id: 'gofre.Contacts.Form.DeletePaymentMethod.Dialog.confirm',
  },
});

export const formErrors = defineMessages({
  nonCompatibleCountry: { id: 'gofre.Contacts.Form.nonCompatibleCountry' },
  nonCompatibleVatNumberType: {
    id: 'gofre.Contacts.Form.nonCompatibleVatNumberType',
  },
  emailInvalid: { id: 'gofre.Contacts.Form.email.invalid' },
  emailDuplicated: { id: 'gofre.Contacts.Form.email.duplicated' },
  postalCodeNumeric: { id: 'gofre.Contacts.Form.postal-code.numeric' },
  selectProvinceRequired: { id: 'gofre.Contacts.Form.province.required' },
  vatNumberInvalid: { id: 'gofre.common.validation.nif_invalid' },
  nifIvaNumberInvalid: { id: 'gofre.Contacts.Form.nifIvaNumberInvalid' },
  dir3FieldInvalid: { id: 'gofre.Contacts.Form.dir3FieldInvalid' },
  vatNumberType: { id: 'gofre.Contacts.Form.vatNumberType' },
  noNifIvaInNonCommunityError: {
    id: 'gofre.Contacts.Form.noNifIvaInNonCommunityError',
  },
  noForeignDocumentInSpainError: {
    id: 'gofre.Contacts.Form.noForeignDocumentInSpainError',
  },
  postalAddress: { id: 'gofre.Contacts.Form.postalAddress' },
});
