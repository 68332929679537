import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: { id: 'gofre.common.action.add_account' },
  subtitle: { id: 'gofre.Payments.AccountingAccount.Modal.subtitle' },
  confirm: { id: 'gofre.Payments.AccountingAccount.Modal.confirm' },
  cancel: { id: 'gofre.common.action.cancel' },
  updateConfirm: { id: 'gofre.common.action.save' },
});

export const tabs = defineMessages({
  tabBankAccount: {
    id: 'gofre.Payments.AccountingAccount.Modal.tab.bankAccount',
  },
  tabCreditCard: {
    id: 'gofre.Payments.AccountingAccount.Modal.tab.creditCard',
  },
  tabCashRegister: {
    id: 'gofre.Payments.AccountingAccount.Modal.tab.cashRegister',
  },
});

export const update = defineMessages({
  title: {
    id: 'gofre.common.action.edit_account',
  },
});

export const deleteButton = defineMessages({
  deleteText: { id: 'gofre.Payments.DeleteAccountingAccount.delete' },
  title: { id: 'gofre.Payments.DeleteAccountingAccount.title' },
  subtitle: { id: 'gofre.Payments.DeleteAccountingAccount.subtitle' },
  text: { id: 'gofre.Payments.DeleteAccountingAccount.text' },
  confirmText: { id: 'gofre.common.action.continue' },
  cancel: { id: 'gofre.common.action.cancel' },
  success: { id: 'gofre.Payments.DeleteAccountingAccount.success' },
  error: { id: 'gofre.Payments.DeleteAccountingAccount.error' },
});

export const formText = defineMessages({
  mandatory: { id: 'gofre.common.validation.mandatory_field' },
  format: { id: 'gofre.Form.field.format' },
  aliasFormatValidation: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.alias.format.validation',
  },
  aliasFormatValidationMax: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.alias.format.validation.max',
  },
  bankAccountAlias: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.bankAccount.alias',
  },
  bankAccountAliasPlaceholder: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.bankAccount.alias.placeholder',
  },
  bankAccountIban: {
    id: 'gofre.common.label.iban',
  },
  bankAccountBic: {
    id: 'gofre.common.label.bic',
  },
  cashRegisterAlias: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.cashRegister.alias',
  },
  cashRegisterAliasPlaceholder: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.cashRegister.alias.placeholder',
  },
  creditCardAlias: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.creditCard.alias',
  },
  creditCardAliasPlaceHolder: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.creditCard.alias.placeholder',
  },
  creditCardNumber: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.creditCard.number',
  },
  creditCardNumberLength: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.creditCard.numberLength',
  },
  creditCardExpireDate: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.creditCard.expireDate',
  },
  creditCardValidation: {
    id: 'gofre.Payments.AccountingAccount.Modal.Form.creditCard.expireDate.validation',
  },
});

export default messages;
