import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconSpinnerNoAnimate = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    clipRule="evenodd"
    path="M7.197 20.413A9.6 9.6 0 0 1 16.8 3.787a9.566 9.566 0 0 1 3.149 2.93l.197-2.815 1.197.084-.322 4.589-4.588-.322.084-1.197 2.32.164A8.37 8.37 0 0 0 16.2 4.826a8.4 8.4 0 1 0 4.2 7.176l1.2-.015-.002.342A9.559 9.559 0 0 1 20.31 16.9a9.6 9.6 0 0 1-13.114 3.512Z"
  />
);

export default IconSpinnerNoAnimate;
