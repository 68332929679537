import { defineMessages } from 'react-intl';

export const formErrors = defineMessages({
  percentage: {
    id: 'gofre.Settings.Form.percentageGreaterThanHundred',
  },
  notificationErrorModel140: {
    id: 'gofre.Settings.Form.Bizka.error-missing-fields',
  },
});

export const navigationDialog = defineMessages({
  title: {
    id: 'gofre.Settings.Form.percentageGreaterThanHundred',
  },
  cancel: {
    id: 'gofre.common.validation.required_field',
  },
  confirm: {
    id: 'gofre.Settings.Form.Bizka.error-missing-fields',
  },
  question: {
    id: 'gofre.Settings.Form.Bizka.error-missing-fields',
  },
});

export const headerMessages = defineMessages({
  title: {
    id: 'gofre.Settings.BaiForm.title',
  },
  subtitle: {
    id: 'gofre.Settings.BaiForm.subtitle',
  },
});

export const shopifyMessages = defineMessages({
  title: {
    id: 'gofre.Settings.BaiForm.shopify.title',
  },
  subtitle: {
    id: 'gofre.Settings.BaiForm.shopify.subtitle',
  },
  warning: {
    id: 'gofre.Settings.BaiForm.shopify.warning',
  },
  sendAutomaticallyText: {
    id: 'gofre.Settings.BaiForm.shopify.sendAutomatically.text',
  },
  sendAutomaticallyDescription: {
    id: 'gofre.Settings.BaiForm.shopify.sendAutomatically.description',
  },
  receiveShopifyEmailText: {
    id: 'gofre.Settings.BaiForm.shopify.receiveShopifyEmail.text',
  },
  receiveShopifyEmailDescription: {
    id: 'gofre.Settings.BaiForm.shopify.receiveShopifyEmail.description',
  },
});

export const dialogMessages = defineMessages({
  title: {
    id: 'gofre.Settings.BaiForm.dialogMessages.title',
  },
  text: {
    id: 'gofre.Settings.BaiForm.dialogMessages.text',
  },
  buttonSecondary: {
    id: 'gofre.Settings.BaiForm.dialogMessages.buttonSecondary',
  },
  buttonPrimary: {
    id: 'gofre.Settings.BaiForm.dialogMessages.buttonPrimary',
  },
  phone: {
    id: 'gofre.common.billin.91_831_88_83',
  },
});
