import { FC, ReactNode } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

interface NavigationGuardProps {
  when: boolean;
  children: (props: {
    isActive: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  }) => ReactNode;
}

const NavigationGuard: FC<NavigationGuardProps> = ({ when, children }) => {
  return (
    <NavigationPrompt when={() => when}>
      {({ isActive, onConfirm, onCancel }) => {
        if (!isActive) return null;

        return children({
          isActive,
          onConfirm,
          onCancel,
        });
      }}
    </NavigationPrompt>
  );
};

export default NavigationGuard;
