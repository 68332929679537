import { defineMessages } from 'react-intl';

import { paymentMethodsMessages } from '../../messages';

const messages = defineMessages({
  title: { id: 'gofre.Payments.edit.title' },
  confirmText: { id: 'gofre.common.action.save' },
  cancel: { id: 'gofre.common.action.cancel' },
  delete: { id: 'gofre.Payments.edit.delete' },
  operationDate: { id: 'gofre.common.label.date' },
  type: { id: 'gofre.Payments.create.type' },
  typeExpense: { id: 'gofre.Payments.create.type-expense' },
  typeIncome: { id: 'gofre.Payments.create.type-income' },
  amount: { id: 'gofre.Payments.create.amount' },
  method: { id: 'gofre.Payments.create.method' },
  methodPlaceholder: { id: 'gofre.common.action.select' },
  maxAmount: { id: 'gofre.Payments.create.maxAmount' },
  minAmount: { id: 'gofre.Payments.create.minAmount' },
  ...paymentMethodsMessages,
});

export default messages;
