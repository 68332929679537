import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: { id: 'gofre.Document.payments.title' },
  pending: { id: 'gofre.Document.payments.pending' },
  openModalCTA: { id: 'gofre.Document.payments.open-modal-cta' },
  edit: { id: 'gofre.common.action.edit' },
  noAccount: { id: 'gofre.Document.payments.no-account' },
  noContact: { id: 'gofre.Document.payments.no-contact' },
  noContactCTA: { id: 'gofre.Document.payments.no-contact-cta' },
  fetchingContactError: {
    id: 'gofre.Document.payments.fetching-contact-error',
  },
  warningPaymentGreaterThanTotal: {
    id: 'gofre.Document.payments.warning-payment-greater-than-total',
  },
  includeInRemittance: {
    id: 'gofre.Document.payments.include-in-remittance',
  },
});

export default messages;
