import { FC } from 'react';
import { Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import {
  background,
  BackgroundProps,
  color,
  ColorProps,
  grid,
  gridArea,
  GridAreaProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

import { selectIsDesktop, selectIsTablet } from 'modules/App/store/selectors';
import { breakpointGutters } from 'modules/Theme/breakpoints';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

import PageLayoutMain from './PageLayoutMain';

interface Props
  extends LayoutProps,
    BackgroundProps,
    ColorProps,
    GridAreaProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps,
    SpaceProps {
  actions?: React.ReactNode | undefined;
  asideColumn?: React.ReactNode | undefined;
  asideContain?: string;
  asideVisibleInMobile?: boolean;
  id?: string;
  mainColumn: React.ReactNode;
  mainColumnBackground?: object;
  mainColumnBorder?: string;
  mainWithoutPadding?: boolean;
  pageSteps?: React.ReactNode;
  pageTitle?: React.ReactNode | undefined;
  titleVisibleInMobile?: boolean;
  isCampaign?: boolean;
  actionColumnHr?: boolean;
}

const Wrapper = styled(Box)`
  ${background}
  ${color}
  ${grid}
  ${gridArea}
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${layout}
  ${space}
`;

const PageLayout: FC<Props> = (props: Props) => {
  const {
    actions,
    asideColumn,
    asideContain,
    asideVisibleInMobile = false,
    id,
    mainColumn,
    mainColumnBackground,
    mainColumnBorder,
    mainWithoutPadding,
    actionColumnHr = true,
    pageSteps,
    pageTitle,
    titleVisibleInMobile = false,
    isCampaign = false,
    ...rest
  } = props;
  const isDesktop = useSelector(selectIsDesktop);
  const isTablet = useSelector(selectIsTablet);
  const theme = useTheme();
  const asideContent =
    asideVisibleInMobile || isDesktop || isTablet
      ? () => (
          <Box
            contain={
              asideContain || isDesktop
                ? asideContain || 'strict'
                : 'layout paint'
            }
            gridArea={pageSteps && { sm: '3 / 1 / 4 / 3', md: 'initial' }}
            marginTop={pageSteps && { sm: '24px', md: 'initial' }}
            tag="aside"
          >
            {asideColumn}
          </Box>
        )
      : undefined;

  const titleContent =
    titleVisibleInMobile || isDesktop || isTablet
      ? () => (
          <Box gridArea={!pageSteps && { md: '1 / 1 / 2 / 3' }}>
            {pageTitle}
          </Box>
        )
      : undefined;

  return (
    <>
      <Wrapper
        data-testid={id}
        display={
          !isCampaign && {
            _: 'grid',
            sm: pageSteps ? 'grid' : 'block',
            md: 'grid',
          }
        }
        gridTemplateColumns={
          !isCampaign && {
            sm: pageSteps && '1fr 1fr',
            md: 'minmax(650px, 8fr) 4fr',
          }
        }
        columnGap={
          !isCampaign && {
            md: `${breakpointGutters.sm}px`,
            lg: `${breakpointGutters.lg}px`,
            xl: `${breakpointGutters.xl}px`,
          }
        }
        fadeIn
        tag="section"
        {...rest}
      >
        {pageTitle && titleContent?.()}
        {pageSteps && <>{pageSteps}</>}
        <PageLayoutMain
          actions={actions}
          mainColumn={mainColumn}
          mainColumnBackground={mainColumnBackground}
          mainColumnBorder={mainColumnBorder}
          mainWithoutPadding={mainWithoutPadding}
          actionColumnHr={actionColumnHr}
          pageSteps={pageSteps}
        />
        {!!asideColumn && asideContent?.()}
        {actions && (
          <Visible xs sm>
            <Box
              backgroundColor="gray0"
              borderTop={`1px solid ${theme.colors.gray200}`}
              bottom="0"
              boxSizing="border-box"
              display="flex"
              flexDirection="column"
              left="0"
              padding="12px 16px"
              position="sticky"
            >
              {actions}
            </Box>
          </Visible>
        )}
      </Wrapper>
    </>
  );
};

Wrapper.defaultProps = {
  gridTemplateRows: {
    _: '1fr auto',
    md: 'auto 1fr',
  },
  height: { _: '100%', sm: 'initial' },
};

export default PageLayout;
