import { FC, useEffect } from 'react';

import { useField } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, FormikField, Link, Text, Warning } from 'modules/Ui';
import Fieldset from 'modules/Ui/Form/Fieldset';
import RadioButtonCardFormik from 'modules/Ui/Formik/RadioButtonCardFormik';

import messages from '../messages';
import { SubForm } from '../types';

const Dir3SubForm: FC<SubForm> = ({ showFaceWarning }) => {
  const { t } = useTranslations();
  const [publicAdministrationForm] = useField<string | undefined>(
    'dir3.publicAdministration'
  );
  const publicAdministration = publicAdministrationForm.value === '1';
  useEffect(() => {
    if (publicAdministration) {
      showFaceWarning?.(false);
    } else {
      showFaceWarning?.(true);
    }
  }, [publicAdministration, showFaceWarning]);
  return (
    <>
      <Box
        aria-labelledby="public-administration"
        role="radiogroup"
        id="dir3-form"
        fadeIn
      >
        <Text
          color="gray800"
          fontSize={16}
          id="public-administration"
          lineHeight={26}
          marginBottom="0"
        >
          {t(messages.publicAdministration)}
          <Text tag="span" color="error" marginLeft="2px">
            *
          </Text>
        </Text>
        <Box
          display="grid"
          gridTemplateColumns="96px 96px 1fr"
          gridTemplateRows="50px"
          columnGap="12px"
          marginBottom={{ _: '32px', sm: '24px' }}
        >
          <RadioButtonCardFormik
            data-testid="dir3.publicAdministration-yes"
            height="100%"
            id="publicAdministration-yes"
            name="dir3.publicAdministration"
            text={t(messages.publicAdministrationAffirmative)}
            value="1"
          />
          <RadioButtonCardFormik
            data-testid="dir3.publicAdministration-no"
            id="publicAdministration-no"
            name="dir3.publicAdministration"
            text={t(messages.publicAdministrationNegative)}
            value="0"
            height="100%"
          />
        </Box>
      </Box>
      {publicAdministration && (
        <Fieldset
          id="public-administration-data"
          legend={t(messages.dataDir3)}
          legendMarginBottom="0"
          fadeIn
        >
          <Text marginBottom="16px" lineHeight={26} fontSize={16}>
            {t<Element, JSX.Element>(messages.infoDataDir3, {
              link: (chunks) => (
                <Link
                  isExternal
                  target="blank"
                  to={t(messages.dir3ExternalUrl)}
                >
                  {chunks}
                </Link>
              ),
            })}
          </Text>
          <Box
            display={{ sm: 'grid' }}
            gridTemplateColumns={{ sm: 'repeat(3, 1fr)' }}
            columnGap={{ sm: '10px' }}
          >
            <FormikField
              id="dir3.accountingOffice"
              data-testid="dir3.accountingOffice"
              name="dir3.accountingOffice"
              required
              label={t(messages.accountingOffice)}
              placeholder={t(messages.accountingOffice)}
            />
            <FormikField
              data-testid="dir3.managementOrgan"
              id="dir3.managementOrgan"
              required
              label={t(messages.managementOrgan)}
              placeholder={t(messages.managementOrgan)}
              name="dir3.managementOrgan"
            />
            <FormikField
              data-testid="dir3.processingUnit"
              id="dir3.processingUnit"
              required
              label={t(messages.processingUnit)}
              placeholder={t(messages.processingUnit)}
              name="dir3.processingUnit"
              maxLength={20}
            />
          </Box>
          <Warning message={t(messages.warning)} />
        </Fieldset>
      )}
    </>
  );
};

export default Dir3SubForm;
