import { ReactNode } from 'react';

import {
  shadow,
  space,
  SpaceProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  gridArea,
  GridAreaProps,
  gridAutoRows,
  GridAutoRowsProps,
  gridColumn,
  GridColumnProps,
  gridTemplateAreas,
  GridTemplateAreasProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  position,
  PositionProps,
  ShadowProps,
  system,
} from 'styled-system';

import { ellipsis } from 'modules/Theme/mixins';
import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import styled, { css } from 'modules/Theme/styled-components';

import { fadeInAnimation } from '../../Theme/mixins/fadeIn';
import fadeOutMixim from '../../Theme/mixins/fadeOut';
import Div from '../Html/Div';

type Tags =
  | 'article'
  | 'aside'
  | 'dd'
  | 'div'
  | 'dl'
  | 'dt'
  | 'li'
  | 'main'
  | 'nav'
  | 'ol'
  | 'p'
  | 'section'
  | 'span'
  | 'table'
  | 'td'
  | 'th'
  | 'tr'
  | 'ul'
  | 'label'
  | 'details'
  | 'summary'
  | 'dialog';

export interface BoxProps
  extends SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps,
    FlexboxProps,
    GridAreaProps,
    GridColumnProps,
    GridTemplateAreasProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps,
    BackgroundProps,
    BorderProps,
    PositionProps,
    ShadowProps,
    GridAutoRowsProps {
  boxSizing?: 'content-box' | 'border-box';
  counterIncrement?: string;
  counterReset?: string;
  contain?:
    | 'content'
    | 'layout'
    | 'none'
    | 'paint'
    | 'size'
    | 'strict'
    | 'style';
  contentVisibility?: 'visible' | 'hidden' | 'auto' | '';
  fontSize?: string;
  filter?: string;
  lineHeight?: string;
  children?: ReactNode;
  className?: string;
  placeItems?: {} | string;
  role?: string;
  tabIndex?: number;
  tag?: Tags;
  fadeIn?: boolean;
  fadeOut?: boolean;
  accessibleHidden?: boolean;
  animationFillMode?: 'none' | 'forwards' | 'backwards' | 'both';
  backdropFilter?: string;
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'initial'
    | 'inherit';
  textOverflow?: 'clip' | 'ellipsis';
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'inherit'
    | 'initial'
    | 'unset';
  transform?: string;
  gap?: {} | string;
  columnGap?: {} | string;
  rowGap?: {} | string;
  gridColumnStart?: {} | string;
  gridColumnEnd?: {} | string;
  gridRowStart?: {} | string;
  gridRowEnd?: {} | string;
  alignContent?: {} | string;
  placeSelf?: {} | string;
  marginInline?: {} | string;
  willChange?: string;
  hoverable?: boolean;
  hasEllipsis?: boolean;
}

const fadeOutAnimation = css`
  animation: ${fadeOutMixim} 0.2s ease-out;
`;

const isAccessibleHide = css`
  ${accesibleHide}
`;

const Box = styled(Div)<BoxProps>`
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${gridArea}
  ${gridAutoRows}
  ${gridColumn}
  ${gridTemplateAreas}
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${layout}
  ${position}
  ${shadow}
  ${space}
  ${typography}
  @supports (content-visibility: auto) {
    ${({ contentVisibility }) =>
      contentVisibility && `content-visibility: ${contentVisibility};`}
  }
  ${({ filter }) => filter && `contain: ${filter};`}
  ${({ backdropFilter }) =>
    backdropFilter && `backdrop-filter: ${backdropFilter};`}
  ${({ fadeIn }) => fadeIn && fadeInAnimation}
  ${({ fadeOut }) => fadeOut && fadeOutAnimation}
  ${({ accessibleHidden }) => accessibleHidden && isAccessibleHide}
  ${({ animationFillMode }) =>
    animationFillMode && `animation-fill-mode: ${animationFillMode};`}
  ${({ transform }) => transform && `transform: ${transform};`}
  ${({ hasEllipsis }) => hasEllipsis && ellipsis}
  ${({ hoverable }) =>
    hoverable &&
    `
      &:hover {
        cursor: pointer;
      }
    `}
  ${system({
    alignContent: {
      property: 'alignContent',
      transform: (value) => `${value}`,
    },
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    rowGap: {
      property: 'rowGap',
      transform: (value) => `${value}`,
    },
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
    gridColumnStart: {
      property: 'gridColumnStart',
      transform: (value) => `${value}`,
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      transform: (value) => `${value}`,
    },
    gridRowStart: {
      property: 'gridRowStart',
      transform: (value) => `${value}`,
    },
    gridRowEnd: {
      property: 'gridRowEnd',
      transform: (value) => `${value}`,
    },
    willChange: {
      property: 'willChange',
      transform: (value) => `${value}`,
    },
    counterIncrement: {
      property: 'counterIncrement',
      scale: 'counterIncrements',
    },
    counterReset: {
      property: 'counterReset',
      scale: 'counterResets',
    },
    boxSizing: {
      property: 'boxSizing',
      scale: 'boxSizings',
    },
    contain: {
      property: 'contain',
      scale: 'contains',
    },
    placeItems: {
      property: 'placeItems',
      scale: 'placeItems',
    },
    placeSelf: {
      property: 'placeSelf',
      scale: 'placeSelf',
    },
    marginInline: {
      property: 'marginInline',
      scale: 'marginInline',
    },
    textTransform: {
      property: 'textTransform',
      scale: 'textTransforms',
    },
    whiteSpace: {
      property: 'whiteSpace',
      scale: 'whiteSpaces',
    },
    contentVisibility: {
      property: 'contentVisibility',
      transform: (value) => `${value}`,
    },
    textOverflow: {
      property: 'textOverflow',
      transform: (value) => `${value}`,
    },
  })}
  `;

export default Box;
