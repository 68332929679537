import { defineMessages } from 'react-intl';

export const remittanceModal = defineMessages({
  cancel: { id: 'gofre.common.action.cancel' },
  submit: { id: 'gofre.common.action.continue' },
  title: { id: 'gofre.dialog.addNewRemittance.title' },
  titleExpense: { id: 'gofre.dialog.addNewRemittance.expenseTitle' },
  titleDocument: { id: 'gofre.dialog.addNewRemittance.titleDocument' },
  editTitle: { id: 'gofre.dialog.addNewRemittance.edit.title' },
  editSave: { id: 'gofre.common.action.save' },
});

export const remittanceForm = defineMessages({
  account: { id: 'gofre.dialog.addNewRemittance.account' },
  b2b: { id: 'gofre.dialog.addNewRemittance.sepa.b2b' },
  bank: { id: 'gofre.dialog.addNewRemittance.bank' },
  concept: { id: 'gofre.form.addNewRemittancel.concept' },
  core: { id: 'gofre.dialog.addNewRemittance.sepa.core' },
  expense: { id: 'gofre.dialog.addNewRemittance.radio.expense' },
  expiration: { id: 'gofre.dialog.addNewRemittance.expiration' },
  id: { id: 'gofre.dialog.addNewRemittance.id' },
  income: { id: 'gofre.dialog.addNewRemittance.radio.income' },
  info: { id: 'gofre.dialog.addNewRemittance.info' },
  sepa: { id: 'gofre.dialog.addNewRemittance.sepa' },
});

export const remittanceNotifications = defineMessages({
  submitError: { id: 'gofre.notifications.remittance.createError' },
  createSuccess: { id: 'gofre.notifications.remittance.createSuccess' },
  editError: { id: 'gofre.notifications.remittance.editError' },
  editSuccess: { id: 'gofre.notifications.remittance.editSuccess' },
  invalidContactPaymentMethodsTitle: {
    id: 'gofre.notifications.remittance.invalidContactPaymentMethodsTitle',
  },
  invalidContactPaymentMethods: {
    id: 'gofre.notifications.remittance.invalidContactPaymentMethods',
  },
  invalidContactPaymentMethodsCTA: {
    id: 'gofre.notifications.remittance.invalidContactPaymentMethodsCTA',
  },
  invalidContactsPaymentMethodsTitle: {
    id: 'gofre.notifications.remittance.invalidContactsPaymentMethodsTitle',
  },
  invalidContactsPaymentMethods: {
    id: 'gofre.notifications.remittance.invalidContactsPaymentMethods',
  },
  invalidContactsPaymentMethodsEndWithName: {
    id: 'gofre.notifications.remittance.invalidContactsPaymentMethodsEndWithName',
  },
  invalidContactsPaymentMethodsEndWithNumber: {
    id: 'gofre.notifications.remittance.invalidContactsPaymentMethodsEndWithNumber',
  },
  limitExceeded: {
    id: 'gofre.notifications.remittance.documentsLimitExceeded',
  },
  limitZipExceeded: { id: 'gofre.common.error.limit_of_100_selected_elements' },
  invalidContactPaymentMethodsUpdateCreateSuccess: {
    id: 'gofre.notifications.remittance.invalidContactPaymentMethodsUpdateCreateSuccess',
  },
  deletedContact: {
    id: 'gofre.notifications.remittance.deletedContact',
  },
});

export const remittanceDetail = defineMessages({
  error: { id: 'gofre.notifications.remittance.error' },
  includedInvoices: { id: 'gofre.remittances.detail.includedInvoices' },
});

export const remittanceDocumentsListMessages = defineMessages({
  identifier: { id: 'gofre.remittances.documentsList.identifier' },
  contact: { id: 'gofre.remittances.documentsList.contact' },
  amount: { id: 'gofre.remittances.documentsList.amount' },
  amountCredit: { id: 'gofre.remittances.documentsList.amountCredit' },
});

export const zeroResults = defineMessages({
  title: { id: 'gofre.Documents.List.ZeroResults.title' },
  subtitle: { id: 'gofre.Documents.List.ZeroResults.subtitle' },
});

export const remittanceBulkActions = defineMessages({
  delete: { id: 'gofre.common.action.delete' },
});
