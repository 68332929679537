import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cancel: { id: 'gofre.common.action.cancel' },
  submit: { id: 'gofre.common.action.save' },
  title: { id: 'gofre.dialog.RemittanceContact.title' },
  subtitle: { id: 'gofre.dialog.RemittanceContact.subtitle' },
  ibanLabel: { id: 'gofre.dialog.RemittanceContact.form-iba.label' },
  mandatory: { id: 'gofre.common.validation.mandatory_field' },
  format: { id: 'gofre.Form.field.format' },
});
