import { FC, useState } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';

import Box from '../Box';
import { OnlyIconButton } from '../Form';
import { IconCheck, IconCopy } from '../Icons';
import { PikitoContainer } from '../PikitoContainer';
import Text from '../Text';
import messages from './messages';

type Props = {
  accessibleText?: string;
  backgroundHoverColor?: string;
  iconHoverSize?: number;
  iconSize?: number;
  timeout?: number;
  value: string;
};

export const ClipboardCopyWithPikito: FC<Props> = ({
  accessibleText,
  backgroundHoverColor,
  iconSize,
  iconHoverSize,
  timeout = 3000,
  value,
}) => {
  const [clicked, setClicked] = useState(false);
  const theme = useTheme();
  const { t } = useTranslations();

  const handleClick = () => {
    setClicked(true);
    navigator?.clipboard?.writeText(value);
    setTimeout(() => {
      setClicked(false);
    }, timeout);
  };
  return clicked ? (
    <Box
      alignItems="center"
      display="flex"
      height={`${iconHoverSize}px`}
      justifyContent="center"
      position="relative"
      width={`${iconHoverSize}px`}
    >
      <IconCheck color={theme.colors.confirmation} size={iconSize} />
      <PikitoContainer
        backgroundColor={theme.colors.auxiliary100A30}
        borderColor={theme.colors.primary200}
        direction="bottom"
        padding="14px 16px"
        position="absolute"
        right="-20px"
        top="-62px"
        width="fit-content"
        zIndex={2}
      >
        <Text
          color={theme.colors.gray600}
          fontSize={12}
          lineHeight={16}
          tag="span"
        >
          {t(messages.copied)}
        </Text>
      </PikitoContainer>
    </Box>
  ) : (
    <>
      <OnlyIconButton
        aria-label={accessibleText}
        backgroundHoverColor={backgroundHoverColor || theme.colors.gray200}
        color={theme.colors.primary400}
        icon={<IconCopy color="inherit" size={iconSize} />}
        onClick={handleClick}
        size={iconHoverSize || 24}
      />
    </>
  );
};
