import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonAdd: { id: 'gofre.Documents.AddProductDialog.dialog-button-add' },
  buttonBack: { id: 'gofre.Documents.AddProductDialog.dialog-button-back' },
  buttonCancel: { id: 'gofre.common.action.cancel' },
  groupTitle: { id: 'gofre.Documents.AddProductDialog.dialog-group-title' },
  referenceLabel: { id: 'gofre.Documents.AddProductDialog.dialog-ref-label' },
  referencePlaceholder: {
    id: 'gofre.common.action.search',
  },
  spinner: { id: 'gofre.Documents.AddProductDialog.dialog-spinner' },
  title: { id: 'gofre.Documents.AddProductDialog.dialog-title' },
  zeroResultSubtitle: {
    id: 'gofre.Documents.AddProductDialog.dialog-zeroResults-subtitle',
  },
  zeroResultTitle: {
    id: 'gofre.Documents.AddProductDialog.dialog-zeroResults-title',
  },
});

export const conceptList = defineMessages({
  search: { id: 'gofre.Documents.AddProductDialog.conceptList.search' },
  column01: { id: 'gofre.Documents.AddProductDialog.conceptList.column01' },
  column02: { id: 'gofre.Documents.AddProductDialog.conceptList.column02' },
  column03: { id: 'gofre.Documents.AddProductDialog.conceptList.column03' },
});

export const zeroState = defineMessages({
  title: { id: 'gofre.Documents.AddProductDialog.dialog-zeroState-title' },
  text01: { id: 'gofre.Documents.AddProductDialog.dialog-zeroState-text01' },
  text02: { id: 'gofre.Documents.AddProductDialog.dialog-zeroState-text02' },
  button: { id: 'gofre.common.action.understood' },
});
