import { endOfYear, formatISO, startOfYear } from 'date-fns';

import { addGteQuery, addLteQuery } from 'utils/query/addQuery';

import { ContactFilters, ContactType } from '../models';

const buildDatesQuery = ({
  from,
  to,
  withDocumentAmounts,
}: {
  from?: Date;
  to?: Date;
  withDocumentAmounts?: boolean;
}) => {
  let query = '';
  if (withDocumentAmounts) {
    if (!from && !to) {
      const fromFallback = formatISO(startOfYear(new Date()), {
        representation: 'date',
      });
      const toFallback = formatISO(endOfYear(new Date()), {
        representation: 'date',
      });
      query += `&query[documentAmountsDate][$gte]=${fromFallback}&query[documentAmountsDate][$lte]=${toFallback}`;
    }
    if (from) {
      const fromFormatted = formatISO(from, { representation: 'date' });
      query += `&query[documentAmountsDate][$gte]=${fromFormatted}`;
    }
    if (to) {
      const toFormatted = formatISO(to, { representation: 'date' });
      query += `&query[documentAmountsDate][$lte]=${toFormatted}`;
    }
  }

  return query;
};

const buildAmountsQuery = (filters: ContactFilters) => {
  const { onlyDebtors, clientType, minAmount, maxAmount } = filters;
  let query = '';

  if (onlyDebtors) {
    const owedKey =
      clientType === ContactType.PROVIDER
        ? 'owedAmountAsProvider'
        : 'owedAmountAsCustomer';
    query += `&query[${owedKey}][$gt]=0`;
  }

  const amountKey =
    clientType === ContactType.PROVIDER
      ? 'billedAmountAsProvider'
      : 'billedAmountAsCustomer';
  if (minAmount) {
    query += addGteQuery(amountKey, `${minAmount}`);
  }
  if (maxAmount) {
    query += addLteQuery(amountKey, `${maxAmount}`);
  }
  return query;
};

export default function buildFiltersQuery(
  filters?: ContactFilters,
  withDocumentAmounts?: boolean
) {
  if (!filters) {
    return '';
  }

  const { searchTerm, clientType, from, to, customSearchFields } = filters;

  let query =
    searchTerm && searchTerm.length > 1
      ? `&search=${encodeURIComponent(searchTerm)}`
      : '';

  if (clientType === ContactType.CUSTOMER) {
    query += '&query[isCustomer][$eq]=true';
  }

  if (clientType === ContactType.PROVIDER) {
    query += '&query[isProvider][$eq]=true';
  }

  query += buildDatesQuery({ from, to, withDocumentAmounts });
  query += buildAmountsQuery(filters);

  if (customSearchFields?.length) {
    for (let i = 0; i < customSearchFields.length; i++) {
      query += `&customSearchFields[${i}]=${customSearchFields[i]}`;
    }
  }

  if (filters.excludeEmptyVatNumber) {
    query += '&query[vatNumber][$nin][0]=&query[vatNumber][$ne]';
  }

  return query;
}
