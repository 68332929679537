import {
  ReactNode,
  DetailedHTMLProps as Props,
  HTMLAttributes as Attributes,
} from 'react';

import {
  color,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  flexbox,
  ColorProps,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  border,
  BorderProps,
  gridArea,
  GridAreaProps,
  gridColumn,
  GridColumnProps,
  gridTemplateAreas,
  GridTemplateAreasProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  system,
} from 'styled-system';

import breakpoints, { min } from 'modules/Theme/breakpoints';
import accessibleHidden from 'modules/Theme/mixins/accessibleHidden';
import ellipsis from 'modules/Theme/mixins/ellipsis';
import multilineEllipsis from 'modules/Theme/mixins/multilineEllipsis';
import styled, { css } from 'modules/Theme/styled-components';

import P from '../Html/P';

export type Sizes = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';

export interface TextProps
  extends SpaceProps,
    ColorProps,
    TypographyProps,
    FlexboxProps,
    LayoutProps,
    ColorProps,
    BorderProps,
    PositionProps,
    GridAreaProps,
    GridColumnProps,
    GridTemplateAreasProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps {
  children: ReactNode;
  ellipsisLines?: number;
  hasEllipsis?: boolean; // Make your Text ellipsable
  hide?: boolean; // Make your Text inivible but visible to the screenReader
  id?: string;
  textSize?: Sizes;
  variant?: 'heading' | 'subheading';
  boxSizing?: 'content-box' | 'border-box';
  tag?: string;
  textTransform?:
    | 'capitalize'
    | 'inherit'
    | 'initial'
    | 'lowercase'
    | 'uppercase'
    | 'none';
  wordBreak?:
    | 'normal'
    | 'break-all'
    | 'keep-all '
    | 'break-word'
    | 'initial'
    | 'inherit'
    | 'uppercase'
    | 'none';
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'inherit'
    | 'initial'
    | 'unset';
  transform?: string;
  textOverflow?: 'clip' | 'ellipsis';
  gap?: {} | string;
  columnGap?: {} | string;
  gridColumnStart?: {} | string;
  gridColumnEnd?: {} | string;
  textDecoration?: string;
}

const textSizes = {
  xxxl: css`
  --text-font-size: ${(props) => props.theme.fontSizes[32]};
  --text-line-height: ${(props) => props.theme.lineHeights[46]};
`,
  xxl: css`
    --text-font-size: ${(props) => props.theme.fontSizes[28]};
    --text-line-height: ${(props) => props.theme.lineHeights[42]};
  `,
  xl: css`
    --text-font-size: ${(props) => props.theme.fontSizes[24]};
    --text-line-height: ${(props) => props.theme.lineHeights[36]};
  `,
  l: css`
  --text-font-size: ${(props) => props.theme.fontSizes[20]};
  --text-line-height: ${(props) => props.theme.lineHeights[32]};
`,
  m: css`
  --text-font-size: ${(props) => props.theme.fontSizes[18]};
  --text-line-height: ${(props) => props.theme.lineHeights[28]};
`,
  s: css`
  --text-font-size: ${(props) => props.theme.fontSizes[16]};
  --text-line-height: ${(props) => props.theme.lineHeights[26]};
`,
  xs: css`
    --text-font-size: ${(props) => props.theme.fontSizes[14]};
    --text-line-height: ${(props) => props.theme.lineHeights[22]};
  `,
  xxs: css`
    --text-font-size: ${(props) => props.theme.fontSizes[12]};
    --text-line-height: ${(props) => props.theme.lineHeights[16]};
  `,
};

const textHide = css`
  ${accessibleHidden};
`;

const textVisible = css<TextProps>`
  ${color}
  ${space}
  ${layout}
  ${gridArea}
  ${gridColumn}
  ${gridTemplateAreas}
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${flexbox}
  ${position}
  ${border}
  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration};`}
  ${({ ellipsisLines }) => ellipsisLines && multilineEllipsis(ellipsisLines)}
  ${({ hasEllipsis }) => hasEllipsis && ellipsis}
  ${({ tag }) => tag === 'strong' && 'font-weight: 600;'}
  ${(props) => (props.textSize ? textSizes[props.textSize] : undefined)}
  ${({ transform }) => transform && `transform: ${transform};`}
  font-size: var(--text-font-size, ${(props) => props.theme.fontSizes[16]});
  line-height: var(--text-line-height, ${(props) =>
    props.theme.lineHeights[26]});
  ${typography}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    rowGap: {
      property: 'rowGap',
      transform: (value) => `${value}`,
    },
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
    boxSizing: {
      property: 'boxSizing',
      scale: 'boxSizings',
    },
    textTransform: {
      property: 'textTransform',
      scale: 'textTransforms',
    },
    wordBreak: {
      property: 'wordBreak',
      scale: 'wordBreaks',
    },
    whiteSpace: {
      property: 'whiteSpace',
      scale: 'whiteSpaces',
    },
    gridColumnStart: {
      property: 'gridColumnStart',
      transform: (value) => `${value}`,
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      transform: (value) => `${value}`,
    },
    textOverflow: {
      property: 'textOverflow',
      transform: (value) => `${value}`,
    },
  })}
`;

const customText = css<TextProps>`
  ${({ hide }) => (hide ? textHide : textVisible)}
`;

const headingStyles = css<TextProps>`
  color: ${(props) => props.theme.colors.brand500};
  font-size: var(--text-font-size, ${(props) => props.theme.fontSizes[20]});
  line-height: var(--text-line-height, ${(props) =>
    props.theme.lineHeights[32]});
  font-weight: 600;
  margin-bottom: 16px;
  @media ${min(breakpoints.sm)} {
    --text-font-size: ${(props) => props.theme.fontSizes[28]};
    --text-line-height: ${(props) => props.theme.lineHeights[46]};
  }
  ${gridArea}
  ${space}
  ${typography}
`;

const subheadingStyles = css<TextProps>`
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.gray800};
  display: block;
  font-size: var(--text-font-size, ${(props) => props.theme.fontSizes[14]});
  font-weight: 600;
  margin-bottom: 32px;
  line-height: var(--text-line-height, ${(props) =>
    props.theme.lineHeights[22]});
  text-transform: ${(props) => props.textTransform ?? 'uppercase'};
  width: 100%;
  ${gridArea}
  ${space}
  ${typography}
`;

export const StyledText = styled(P)<TextProps>`
${({ hide }) => hide && textHide}
${({ variant }) => !variant && customText}
${({ variant }) => variant === 'heading' && headingStyles}
${({ variant }) => variant === 'subheading' && subheadingStyles}
  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration};`}
${system({
  gridColumnStart: {
    property: 'gridColumnStart',
    transform: (value) => `${value}`,
  },
  gridColumnEnd: {
    property: 'gridColumnEnd',
    transform: (value) => `${value}`,
  },
  textTransform: {
    property: 'textTransform',
    scale: 'textTransforms',
  },
  columnGap: {
    property: 'columnGap',
    transform: (value) => `${value}`,
  },
})}
`;

export type TextFullProps = TextProps &
  Omit<Props<Attributes<HTMLElement>, HTMLParagraphElement>, 'color'>;

const Text = (props: TextFullProps) => {
  const {
    boxSizing,
    children,
    columnGap,
    ellipsisLines,
    gap,
    gridColumnEnd,
    gridColumnStart,
    hasEllipsis,
    hide,
    id,
    tag = 'p',
    textDecoration,
    textSize,
    textTransform,
    transform,
    whiteSpace,
    wordBreak,
    ...rest
  } = props;
  return (
    <StyledText
      boxSizing={boxSizing}
      columnGap={columnGap || null}
      ellipsisLines={ellipsisLines}
      gap={gap || null}
      gridColumnEnd={gridColumnEnd ?? ''}
      gridColumnStart={gridColumnStart ?? ''}
      hasEllipsis={hasEllipsis}
      hide={hide}
      id={id}
      textDecoration={textDecoration}
      textSize={textSize}
      textTransform={textTransform ?? ''}
      transform={transform}
      whiteSpace={whiteSpace}
      wordBreak={wordBreak}
      {...{
        tag,
        ...rest,
      }}
    >
      {children}
    </StyledText>
  );
};

Text.defaultProps = {
  /* eslint-disable react/default-props-match-prop-types */
  color: 'gray600',
  /* eslint-enable react/default-props-match-prop-types */
};

export default Text;
