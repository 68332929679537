import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: { id: 'gofre.common.action.cancel' },
  cvv: { id: 'gofre.Subscriptions.Create.cvv' },
  emailLabel: { id: 'gofre.Subscriptions.Create.email.label' },
  emailPlaceholder: { id: 'gofre.Subscriptions.Create.email.placeholder' },
  enter: { id: 'gofre.Subscriptions.Create.enter' },
  enterEmail: { id: 'gofre.Subscriptions.Create.email.enter' },
  expires: { id: 'gofre.Subscriptions.Create.expires' },
  expiresPlaceholder: { id: 'gofre.Subscriptions.Create.expires.placeholder' },
  holder: { id: 'gofre.Subscriptions.Create.holder' },
  holderPlaceholder: { id: 'gofre.Subscriptions.Create.holder.placeholder' },
  number: { id: 'gofre.Subscriptions.Create.number' },
  numberPlaceholder: { id: 'gofre.Subscriptions.Create.number.placeholder' },
  submit: { id: 'gofre.Subscriptions.Create.submit' },
  emailFormatError: { id: 'gofre.Subscriptions.Create.email.field.invalid' },
  emailFormatHelp: { id: 'gofre.Subscriptions.Create.email.field.help' },
});
