import { defineMessages } from 'react-intl';

export const cancelSubscription = defineMessages({
  cancelSubscriptionButton: {
    id: 'gofre.Subscription.Detail.cancel-subscription',
  },
});

export const cancelSubscriptionDialog = defineMessages({
  confirmText: {
    id: 'gofre.common.action.cancel_subscription',
  },
  subtitle: { id: 'gofre.Subscription.cancelSubscriptionDialog.subtitle' },
  contentText01: {
    id: 'gofre.Subscription.cancelSubscriptionDialog.contentText01',
  },
  contentText02: {
    id: 'gofre.Subscription.cancelSubscriptionDialog.contentText02',
  },
});
