import { expenseLedgerTexts } from 'modules/Documents/List/components/SortOutExpensesModal/messages';
import { ExpenseCategories } from 'modules/Documents/models/document';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import theme from 'modules/Theme';

interface Expense {
  color: string;
  label: string;
}

type ExpenseData = {
  [key: string]: Expense;
};

export const getEmptyExpenseData = (t: TranslationFunction) => {
  return {
    color: '#6599FF',
    label: t(expenseLedgerTexts.noClasification),
  };
};

export const getUncategorizedExpenseData = (t: TranslationFunction) => {
  return {
    color: theme.colors.brand400,
    label: t(expenseLedgerTexts.noCategory),
  };
};

const getExpensesData = (t: TranslationFunction) => {
  return {
    [ExpenseCategories.Ware]: {
      color: theme.colors.brand700,
      label: t(expenseLedgerTexts.ware),
    },
    [ExpenseCategories.RawMaterials]: {
      color: theme.colors.primary300,
      label: t(expenseLedgerTexts.rawMaterials),
    },
    [ExpenseCategories.Supplies]: {
      color: theme.colors.accent500,
      label: t(expenseLedgerTexts.supplies),
    },
    [ExpenseCategories.OtherCompanies]: {
      color: theme.colors.rejected,
      label: t(expenseLedgerTexts.otherCompanies),
    },
    [ExpenseCategories.Returns]: {
      color: '#FFA8C7',
      label: t(expenseLedgerTexts.returns),
    },
    [ExpenseCategories.VariationsMerchadise]: {
      color: '#E8BD69',
      label: t(expenseLedgerTexts.variationsMerchadiseInventories),
    },
    [ExpenseCategories.VariationsRawMaterials]: {
      color: '#DAD0FF',
      label: t(expenseLedgerTexts.variationsRawMaterialInventories),
    },
    [ExpenseCategories.VariationsOtherSupplies]: {
      color: theme.colors.primary400,
      label: t(expenseLedgerTexts.variationsInventoriesOtherSupplies),
    },
    [ExpenseCategories.ResearchExpendidures]: {
      color: '#7A2C84',
      label: t(expenseLedgerTexts.researchExpenditures),
    },
    [ExpenseCategories.Renting]: {
      color: theme.colors.confirmation,
      label: t(expenseLedgerTexts.renting),
    },
    [ExpenseCategories.RepairsAndMaintenance]: {
      color: '#D6A1C6',
      label: t(expenseLedgerTexts.repairsAndMaintenance),
    },
    [ExpenseCategories.RetentionServices]: {
      color: theme.colors.stateNew,
      label: t(expenseLedgerTexts.professionalServices),
    },
    [ExpenseCategories.Logistics]: {
      color: theme.colors.avatarOrange,
      label: t(expenseLedgerTexts.logistics),
    },
    [ExpenseCategories.Insurance]: {
      color: theme.colors.pending,
      label: t(expenseLedgerTexts.insurance),
    },
    [ExpenseCategories.BankingExpenses]: {
      color: theme.colors.tagsConfirmationAccesible,
      label: t(expenseLedgerTexts.bankingExpenses),
    },
    [ExpenseCategories.Advertising]: {
      color: '#A1FBD8',
      label: t(expenseLedgerTexts.advertising),
    },
    [ExpenseCategories.GeneralSupplies]: {
      color: theme.colors.accent500,
      label: t(expenseLedgerTexts.generalSupplies),
    },
    [ExpenseCategories.OtherServices]: {
      color: '#FFEF9A',
      label: t(expenseLedgerTexts.otherServices),
    },
    [ExpenseCategories.CurrentTax]: {
      color: '#E15E84',
      label: t(expenseLedgerTexts.currentTax),
    },
    [ExpenseCategories.DeferredTax]: {
      color: '#88D27F',
      label: t(expenseLedgerTexts.deferredTax),
    },
    [ExpenseCategories.OtherTaxes]: {
      color: '#C3E58D',
      label: t(expenseLedgerTexts.otherTaxes),
    },
    [ExpenseCategories.Salary]: {
      color: '#A790E3',
      label: t(expenseLedgerTexts.salary),
    },
    [ExpenseCategories.Indemnifications]: {
      color: '#D8E8C6',
      label: t(expenseLedgerTexts.indemnifications),
    },
    [ExpenseCategories.SocialTaxes]: {
      color: theme.colors.brand300,
      label: t(expenseLedgerTexts.socialTaxes),
    },
    [ExpenseCategories.SocialExpenses]: {
      color: theme.colors.brand500,
      label: t(expenseLedgerTexts.socialExpenses),
    },
    [ExpenseCategories.BankDebtInterest]: {
      color: '#01C6AE',
      label: t(expenseLedgerTexts.bankDebtInterest),
    },
    [ExpenseCategories.InterestDebtsOtherCompanies]: {
      color: '#B88A4D',
      label: t(expenseLedgerTexts.interestDebtsOtherCompanies),
    },
    [ExpenseCategories.TangibleFixedEquipment]: {
      color: '#A82D8D',
      label: t(expenseLedgerTexts.tangibleFixedEquipment),
    },
    [ExpenseCategories.OtherFinancialExpenses]: {
      color: theme.colors.gray200,
      label: t(expenseLedgerTexts.otherFinancialExpenses),
    },
    [ExpenseCategories.ExceptionalExpenses]: {
      color: '#60AFBB',
      label: t(expenseLedgerTexts.exceptionalExpenses),
    },
    [ExpenseCategories.AmortizationIntangibleFixedAssets]: {
      color: '#A4D1D8',
      label: t(expenseLedgerTexts.amortizationIntangibleFixedAssets),
    },
    [ExpenseCategories.AmortizationPropertyAndEquipment]: {
      color: '#D94B1E',
      label: t(expenseLedgerTexts.amortizationPropertyAndEquipment),
    },
    [ExpenseCategories.AmortizationInvestmentProperty]: {
      color: '#9E1A00',
      label: t(expenseLedgerTexts.amortizationInvestmentProperty),
    },
    [ExpenseCategories.Uncategorized]: {
      color: theme.colors.brand400,
      label: t(expenseLedgerTexts.noCategory),
    },
    [ExpenseCategories.Empty]: {
      color: theme.colors.brand400,
      label: t(expenseLedgerTexts.noCategory),
    },
    [ExpenseCategories.Owed]: {
      color: theme.colors.avatarOrange,
      label: t(expenseLedgerTexts.owed),
    },
  } as ExpenseData;
};

export default getExpensesData;
