export enum Roles {
  OWNER = 'owner',
  ADMIN = 'admin',
  EDITOR = 'editor',
  READER = 'reader',
  BOOKKEEPER = 'bookkeeper',
}

export interface Role {
  id: string;
  name: Roles;
}

export const isRole = (role: string): role is Roles => {
  return Object.values(Roles).includes(role as Roles);
};
