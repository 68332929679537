import { defineMessages } from 'react-intl';

export const formErrors = defineMessages({
  lastExportToBeforeFromDate: {
    id: 'gofre.Bookkeeper.Exporter.error.beforeFromDate',
  },
  fetchError: {
    id: 'gofre.Bookkeeper.Exporter.error.fetch-customers',
  },
  fetchDetailError: {
    id: 'gofre.Bookkeeper.error.fetch-customer',
  },
  startPeriodRequired: {
    id: 'gofre.Bookkeeper.Exporter.error.startPeriodRequired',
  },
  endPeriodRequired: {
    id: 'gofre.Bookkeeper.Exporter.error.endPeriodRequired',
  },
});

export const form = defineMessages({
  addSalesCode: { id: 'gofre.Bookkeeper.Exporter.Expandable.addSalesCode' },
  expandableTitle: { id: 'gofre.Bookkeeper.Exporter.Expandable.title' },
  salesCode: { id: 'gofre.Bookkeeper.Exporter.Expandable.salesCode' },
  title: { id: 'gofre.Bookkeeper.Exporter.form.title' },
});

export const dateRange = defineMessages({
  t1: { id: 'gofre.Bookkeeper.Exporter.t1.title' },
  t2: { id: 'gofre.Bookkeeper.Exporter.t2.title' },
  t3: { id: 'gofre.Bookkeeper.Exporter.t3.title' },
  t4: { id: 'gofre.Bookkeeper.Exporter.t4.title' },
  currentMonth: { id: 'gofre.Bookkeeper.Exporter.currentMonth.title' },
  prevMonth: { id: 'gofre.Bookkeeper.Exporter.prevMonth.title' },
  dateFrom: { id: 'gofre.Bookkeeper.Exporter.dateFrom.title' },
  dateTo: { id: 'gofre.Bookkeeper.Exporter.dateTo.title' },
  dateFormatPlaceholder: {
    id: 'gofre.Bookkeeper.Exporter.date-format-placeholder',
  },
  legend: { id: 'gofre.Bookkeeper.Exporter.legend' },
  info: { id: 'gofre.Bookkeeper.Exporter.info' },
});

export const exportOptions = defineMessages({
  delete: { id: 'gofre.Bookkeeper.Exporter.Options.delete' },
  title: { id: 'gofre.Bookkeeper.Exporter.Options.title' },
  xsl: { id: 'gofre.Bookkeeper.Exporter.Options.xsl' },
  xslExternal: { id: 'gofre.Bookkeeper.Exporter.Options.xslExternal' },
  xslExternalContasol: {
    id: 'gofre.Bookkeeper.Exporter.Options.xslExternalContasol',
  },
  xslExternalAplifisa: {
    id: 'gofre.Bookkeeper.Exporter.Options.xslExternalAplifisa',
  },
  zip: { id: 'gofre.Bookkeeper.Exporter.Options.zip' },
});

export const salesCodeDialog = defineMessages({
  cancel: { id: 'gofre.common.action.cancel' },
  confirm: { id: 'gofre.Bookkeeper.SalesDialog.confirm' },
  fieldLabel: { id: 'gofre.Bookkeeper.SalesDialog.fieldLabel' },
  text: { id: 'gofre.Bookkeeper.SalesDialog.text' },
  title: { id: 'gofre.Bookkeeper.SalesDialog.title' },
  error: { id: 'gofre.Bookkeeper.SalesDialog.error' },
  success: { id: 'gofre.Bookkeeper.SalesDialog.success' },
});

export const exporterDiamaconHelp = defineMessages({
  import: { id: 'gofre.Bookkeeper.ExporterHelp.import' },
  diamacon: { id: 'gofre.Bookkeeper.ExporterHelp.title.diamacon' },
  a3: { id: 'gofre.Bookkeeper.ExporterHelp.title.a3' },
  sage: { id: 'gofre.Bookkeeper.ExporterHelp.title.sage' },
  videoDescription: { id: 'gofre.Bookkeeper.ExporterHelp.video.text' },
  donwloadDescription: { id: 'gofre.Bookkeeper.ExporterHelp.donwload.text' },
  download: { id: 'gofre.Bookkeeper.ExporterHelp.donwload' },
  ThumbnailTitle: { id: 'gofre.Bookkeeper.ExporterHelp.Thumbnail.title' },
  ThumbnailText: { id: 'gofre.Bookkeeper.ExporterHelp.Thumbnail.text' },
});

export const exporterContasolHelp = defineMessages({
  title: { id: 'gofre.Bookkeeper.ExporterHelp.contasol.title' },
  content: { id: 'gofre.Bookkeeper.ExporterHelp.contasol.content' },
  ThumbnailText: {
    id: 'gofre.Bookkeeper.ExporterHelp.contasol.Thumbnail.text',
  },
  ThumbnailText1: {
    id: 'gofre.Bookkeeper.ExporterHelp.contasol.Thumbnail1.text',
  },
  ThumbnailText2: {
    id: 'gofre.Bookkeeper.ExporterHelp.contasol.Thumbnail2.text',
  },
  ThumbnailText3: {
    id: 'gofre.Bookkeeper.ExporterHelp.contasol.Thumbnail3.text',
  },
  ThumbnailText4: {
    id: 'gofre.Bookkeeper.ExporterHelp.contasol.Thumbnail4.text',
  },
  ThumbnailText01: {
    id: 'gofre.Bookkeeper.ExporterHelp.contasol.Thumbnail01.text',
  },
  ThumbnailText02: {
    id: 'gofre.Bookkeeper.ExporterHelp.contasol.Thumbnail02.text',
  },
  ThumbnailText03: {
    id: 'gofre.Bookkeeper.ExporterHelp.contasol.Thumbnail03.text',
  },
  ThumbnailText04: {
    id: 'gofre.Bookkeeper.ExporterHelp.contasol.Thumbnail04.text',
  },
});

export const exporterA3Help = defineMessages({
  import: { id: 'gofre.Bookkeeper.ExporterHelp.import' },
  videoDescription: { id: 'gofre.Bookkeeper.ExporterHelp.A3.video.text' },
  donwloadDescription: { id: 'gofre.Bookkeeper.ExporterHelp.A3.donwload.text' },
  download: { id: 'gofre.Bookkeeper.ExporterHelp.donwload' },
  ThumbnailTitle: { id: 'gofre.Bookkeeper.ExporterHelp.Thumbnail.title' },
  ThumbnailText: { id: 'gofre.Bookkeeper.ExporterHelp.Thumbnail.text' },
  contactDescription: {
    id: 'gofre.Bookkeeper.ExporterHelp.A3.contact.text',
  },
  phone: { id: 'gofre.Bookkeeper.ExporterHelp.A3.phone' },
  email: { id: 'gofre.common.label.gestorias_billin_eu' },
});

export const exporterSageDespachosHelp = defineMessages({
  import: { id: 'gofre.Bookkeeper.ExporterHelp.import' },
  videoDescription: {
    id: 'gofre.Bookkeeper.ExporterHelp.SageDespachos.video.text',
  },
  donwloadDescription: {
    id: 'gofre.Bookkeeper.ExporterHelp.SageDespachos.donwload.text',
  },
  download: { id: 'gofre.Bookkeeper.ExporterHelp.donwload' },
  ThumbnailTitle: { id: 'gofre.Bookkeeper.ExporterHelp.Thumbnail.title' },
  ThumbnailText: { id: 'gofre.Bookkeeper.ExporterHelp.Thumbnail.text' },
});

export const exporterSageContaplusHelp = defineMessages({
  import: { id: 'gofre.Bookkeeper.ExporterHelp.import' },
  bannerDescription: {
    id: 'gofre.Bookkeeper.ExporterHelp.SageContaplus.banner.text',
  },
  donwloadDescription: {
    id: 'gofre.Bookkeeper.ExporterHelp.SageContaplus.donwload.text',
  },
  download: { id: 'gofre.Bookkeeper.ExporterHelp.donwload' },
  ThumbnailTitle: { id: 'gofre.Bookkeeper.ExporterHelp.Thumbnail.title' },
  ThumbnailText: { id: 'gofre.Bookkeeper.ExporterHelp.Thumbnail.text' },
  contactDescription: {
    id: 'gofre.Bookkeeper.ExporterHelp.SageContaplus.contact.text',
  },
  phone: { id: 'gofre.common.billin.91_831_88_83' },
  email: { id: 'gofre.common.label.gestorias_billin_eu' },
});
