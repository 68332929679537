import { defineMessages } from 'react-intl';

const messages = defineMessages({
  error: { id: 'gofre.Documents.List.error' },
  detailError: { id: 'gofre.Documents.Detail.error' },
  arrivalDate: { id: 'gofre.Expenses.List.ToReview.arrivalDate' },
  sentBy: { id: 'gofre.Expenses.List.ToReview.sentBy' },
  subject: { id: 'gofre.Expenses.List.ToReview.subject' },
  fileType: { id: 'gofre.Expenses.List.ToReview.fileType' },
  status: { id: 'gofre.Expenses.List.ToReview.status' },
  deleted: { id: 'gofre.Expenses.ToReview.deleted' },
  errorDeleting: { id: 'gofre.Expenses.ToReview.errorDeleting' },
  sentSource: { id: 'gofre.Expenses.List.ToReview.sentSource' },
  manual: { id: 'gofre.Expenses.List.ToReview.tooltip.manual' },
  email: { id: 'gofre.Expenses.List.ToReview.tooltip.email' },
});

export default messages;
