import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cancel: { id: 'gofre.common.action.cancel' },
  confirmText: { id: 'gofre.common.action.continue' },
  title: { id: 'gofre.Payments.DeletePaymentsModal.title' },
  subtitle: { id: 'gofre.Payments.DeletePaymentsModal.subtitle' },
  text: { id: 'gofre.Payments.DeletePaymentsModal.text' },
  success: { id: 'gofre.Payments.DeletePaymentsModal.success' },
  error: { id: 'gofre.Payments.DeletePaymentsModal.error' },
  limitExceeded: { id: 'gofre.common.error.limit_of_100_selected_elements' },
});
