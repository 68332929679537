import { defineMessages } from 'react-intl';

export const changeCardModal = defineMessages({
  confirm: {
    id: 'gofre.common.action.save',
  },
  cancel: {
    id: 'gofre.common.action.cancel',
  },
  subtitle: { id: 'gofre.Subscription.changeCardModal.subtitle' },
  title: {
    id: 'gofre.Subscription.changeCardModal.title',
  },
  success: {
    id: 'gofre.Subscription.changeCardModal.success',
  },
  updating: {
    id: 'gofre.Subscription.changeCardModal.updating',
  },
});

export const changeCard = defineMessages({
  title: { id: 'gofre.Subscription.changeCard.title' },
  subtitle: { id: 'gofre.Subscription.changeCard.subtitle' },
  button: { id: 'gofre.Subscription.changeCard.button' },
  expiration: { id: 'gofre.Subscription.changeCard.expiration' },
});
