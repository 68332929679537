import { defineMessages } from 'react-intl';

export const notificationsMessages = defineMessages({
  error: { id: 'gofre.remittances.list.notifications.error' },
});

export const zeroState = defineMessages({
  zeroStateTitle: { id: 'gofre.remittances.zeroState.title' },
  zeroStateSubitle: { id: 'gofre.remittances.zeroState.subtitle' },
  zeroStateBait: { id: 'gofre.remittances.zeroState.bait.subtitle' },
  zeroStateSubiitleMobile: {
    id: 'gofre.remittances.zeroState.subtitle.mobile',
  },
  zeroStateSubiitleNoUnlimited: {
    id: 'gofre.remittances.zeroState.subtitle.noUnlimited',
  },
  zeroStateButton: { id: 'gofre.remittances.zeroState.button' },
  zeroStateLink: { id: 'gofre.remittances.zeroState.link' },
});

export const addNewRemittanceFormModal = defineMessages({
  titleTotalCount: {
    id: 'gofre.remittances.addNewRemittanceForm.titleTotalCount',
  },
  titleTotalAmount: {
    id: 'gofre.remittances.addNewRemittanceForm.titleTotalAmount',
  },
});

export const remittancesTable = defineMessages({
  createdAt: { id: 'gofre.remittances.list.header.createdAt' },
  type: { id: 'gofre.remittances.list.header.type' },
  directDebit: { id: 'gofre.remittances.list.header.directDebit' },
  creditTransfer: { id: 'gofre.remittances.list.header.creditTransfer' },
  name: { id: 'gofre.common.label.concept' },
  businessBankAlias: { id: 'gofre.remittances.list.header.businessBankAlias' },
  dueDate: { id: 'gofre.remittances.list.header.dueDate' },
  remittanceDocumentsCount: {
    id: 'gofre.remittances.list.header.remittanceDocumentsCount',
  },
  amount: { id: 'gofre.common.label.total' },
  status: { id: 'gofre.remittances.list.header.status' },
  pending: { id: 'gofre.common.label.pending' },
  completed: { id: 'gofre.remittances.list.completed' },
  edit: { id: 'gofre.common.action.edit' },
  markAsComplete: { id: 'gofre.remittances.list.actions.markAsComplete' },
  markAsPending: { id: 'gofre.remittances.list.actions.markAsPending' },
});

export const markAsCompletedRemittanceModal = defineMessages({
  title: { id: 'gofre.remittances.modal.complete.title' },
  text: { id: 'gofre.remittances.modal.complete.text' },
  cancel: { id: 'gofre.common.action.cancel' },
  submit: { id: 'gofre.common.action.continue' },
  notificationDone: { id: 'gofre.remittances.modal.complete.notificationDone' },
  error: { id: 'gofre.remittances.modal.complete.error' },
});

export const markAsPendingRemittanceModal = defineMessages({
  title: { id: 'gofre.remittances.modal.pending.title' },
  text: { id: 'gofre.remittances.modal.pending.text' },
  cancel: { id: 'gofre.common.action.cancel' },
  submit: { id: 'gofre.common.action.continue' },
  notificationDone: { id: 'gofre.remittances.modal.pending.notificationDone' },
  error: { id: 'gofre.remittances.modal.pending.error' },
});

export const remittancesTableBulkActions = defineMessages({
  downloadXML: { id: 'gofre.remittances.list.bulkActions.download-xml' },
  downloadZIP: { id: 'gofre.common.action.download_zip' },
  delete: { id: 'gofre.common.action.delete' },
});
