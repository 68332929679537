import { ReactNode, FC } from 'react';

import { space, SpaceProps } from 'styled-system';

import { downloadAttachedFile } from 'modules/Documents/List/services/downloadDocument';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';
import Button from 'modules/Ui/Form/Button';
import { IconCross } from 'modules/Ui/Icons';
import Link from 'modules/Ui/Link';
import Text from 'modules/Ui/Text';

import { fileMessages } from './messages';

interface Props extends SpaceProps {
  clickable?: boolean;
  contentLength?: string;
  documentId?: string;
  fileName?: string;
  icon?: string | ReactNode;
  id?: string;
  isResponsive?: boolean;
  loading?: boolean;
  onRemove?: () => void;
  text?: string;
}

const StyledText = styled(Text)<Props>`
  ${space}
  &::after {
    content: attr(data-attribute);
    display: block;
  }
  @media (hover: hover) {
    &:hover {
      text-decoration: ${({ clickable }) =>
        clickable ? 'underline' : 'initial'};
    }
  }
`;

const MainFileAttachment: FC<Props> = ({
  documentId,
  clickable = false,
  isResponsive = false,
  fileName = '',
  contentLength = '',
  loading,
  text,
  icon,
  onRemove,
  ...rest
}) => {
  const { formatBytes } = useTranslations();
  const { t } = useTranslations();
  const theme = useTheme();
  if (loading) {
    return null;
  }

  const bytes = parseInt(contentLength, 10);
  const nameParts = fileName?.split('.');
  const extension = nameParts?.pop();
  const name = nameParts?.join('.');
  const Wrap = clickable ? ClickableWrapperComponent : Box;
  return (
    <Wrap
      backgroundColor={theme.colors.auxiliary}
      boxSizing="border-box"
      display="flex"
      documentId={documentId}
      fileName={fileName}
      height="64px"
      padding={{ _: '20px 16px 10px 20px', sm: '16px 16px 10px 20px' }}
      {...rest}
    >
      {icon && (
        <Box tag="span" height="24px" width="24px" marginRight="18px">
          {icon}
        </Box>
      )}
      <Text
        display={isResponsive ? { sm: 'flex' } : undefined}
        flex="1"
        marginRight="4px"
        overflow="hidden"
        tag="span"
      >
        <StyledText
          color="gray800"
          data-attribute={extension ? `.${extension}` : ''}
          display="flex"
          flex={isResponsive ? { sm: '0' } : '1'}
          fontSize={14}
          fontWeight="600"
          lineHeight={16}
          marginBottom={{ _: '4px', sm: '0' }}
          tag="span"
          text={text}
          clickable={clickable}
        >
          <StyledText
            color="inherit"
            fontSize="inherit"
            hasEllipsis
            lineHeight="inherit"
            tag="span"
            text={text}
            clickable={clickable}
          >
            {name} {text}
          </StyledText>
        </StyledText>
        {!text && (
          <Text
            display={{ _: 'block', sm: 'initial' }}
            fontSize={14}
            lineHeight={16}
            marginLeft={isResponsive ? { sm: '8px' } : undefined}
            tag="span"
          >
            ({formatBytes(bytes)})
          </Text>
        )}
      </Text>
      {onRemove && (
        <Button
          aria-label={t(fileMessages.fileRemove)}
          data-testid="file-remove"
          height="24px"
          onClick={() => onRemove()}
          type="button"
          width="24px"
        >
          <IconCross color="brand500" />
        </Button>
      )}
    </Wrap>
  );
};

const ClickableWrapperComponent: FC<{
  documentId?: string;
  fileName: string;
}> = ({ documentId, fileName, children, ...props }) => {
  return (
    <Box backgroundColor="auxiliary">
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          if (documentId) {
            downloadAttachedFile(documentId);
          }
        }}
        {...props}
      >
        {children}
      </Link>
    </Box>
  );
};

export default MainFileAttachment;
