import { useState, FC } from 'react';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import updateDocuments, {
  invalidateQueries,
} from 'modules/Documents/List/services/updateDocuments';
import {
  Document,
  DocumentType,
  ExpenseCategories,
} from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { normalizeExpenseCategoryParam } from '../../helpers/normalizeExpenseCategoryParam';
import { Option } from './hooks/useExpenseLedger';
import messages from './messages';
import SortOutExpensesModal from './SortOutExpensesModal';
import { SortOutExpensesDocumentsModalProps } from './types';

const SortOutExpensesDocumentsModal: FC<SortOutExpensesDocumentsModalProps> = ({
  ids,
  excludeIds,
  documents,
  filters,
}) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const selectedDocuments =
    documents &&
    ids
      .map((id: string) =>
        documents?.filter((document: Document) => document.id === id)
      )
      .flat();
  const hasSameCategory = selectedDocuments?.every(
    (val: any, _: number, arr: Array<Document>) =>
      val.category === arr[0].category
  );
  const selectedCategory: Option | undefined = (!excludeIds &&
    selectedDocuments &&
    hasSameCategory && {
      id: selectedDocuments[0].category as ExpenseCategories,
      label: '',
    }) || {
    id: ExpenseCategories.Empty,
    label: '',
  };

  const [optionSelected, setOptionSelected] = useState<Option | undefined>(
    selectedCategory
  );

  const { mutate } = useBlockingMutation(updateDocuments, {
    onSuccess: ({ data }) => {
      notifications.success(
        t(messages.success, {
          b: (chunks) => <b>{chunks}</b>,
          count: data.count,
          expenseType: optionSelected?.label,
        }),
        { messageKey: messages.success.id }
      );
      invalidateAndUpdate({ invalidateQueries });
      closeModal();
    },
    onError: () => {
      notifications.error(messages.error.id);
      closeModal();
    },
  });

  const onConfirm = async () => {
    mutate({
      ids,
      excludeIds,
      category: normalizeExpenseCategoryParam(optionSelected?.id ?? null),
      documentType: DocumentType.EXPENSE,
      filters,
    });
    closeModal();
  };

  return (
    <SortOutExpensesModal
      onConfirm={onConfirm}
      setOptionSelected={setOptionSelected}
      optionSelected={optionSelected}
      loading={false}
    />
  );
};

export default SortOutExpensesDocumentsModal;
