import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'gofre.Quotes.AddContact.title' },
  warning: { id: 'gofre.Quotes.AddContact.warning' },
  submit: { id: 'gofre.common.action.save_data' },
  cancel: { id: 'gofre.common.action.cancel' },
  duplicated: { id: 'gofre.Contacts.Update.duplicated' },
});

export const form = defineMessages({
  country: { id: 'gofre.Contacts.Form.country' },
  fiscalName: { id: 'gofre.Contacts.Form.fiscal-name' },
  vatNumber: { id: 'gofre.Contacts.Form.vat-number' },
  vatNumberPlaceholder: {
    id: 'gofre.common.label.nif_of_contact',
  },
  postalAddress: { id: 'gofre.Contacts.Form.postal-address' },
  postalCode: { id: 'gofre.Contacts.Form.postal-code-placeholder' },
  city: { id: 'gofre.Contacts.Form.city' },
  province: { id: 'gofre.Contacts.Form.province' },
  provincePlaceholder: { id: 'gofre.common.action.select' },
  countryPlaceholder: { id: 'gofre.Manager.Create.Form.country.placeholder' },
});
