import { defineMessages } from 'react-intl';

const messsages = defineMessages({
  partialTitle: { id: 'gofre.Documents.Rectify.Partial.title' },
  title: { id: 'gofre.Documents.Rectify.title' },
  titleDesktop: { id: 'gofre.Documents.Rectify.title.desktop' },
  loadingTitle: { id: 'gofre.Documents.Rectify.loading.title' },
  loadingDescription: {
    id: 'gofre.Documents.Rectify.loading.loadingDescription',
  },
  correctiveCause1: {
    id: 'gofre.Documents.Rectify.correctiveCause1',
  },
  correctiveCause2: {
    id: 'gofre.Documents.Rectify.correctiveCause2',
  },
  correctiveCause3: {
    id: 'gofre.Documents.Rectify.correctiveCause3',
  },
  correctiveCause4: {
    id: 'gofre.Documents.Rectify.correctiveCause4',
  },
  correctiveCause5: {
    id: 'gofre.Documents.Rectify.correctiveCause5',
  },
  correctiveCauseInfo1: {
    id: 'gofre.Documents.Rectify.correctiveCauseInfo1',
  },
  correctiveCauseInfo2: {
    id: 'gofre.Documents.Rectify.correctiveCauseInfo2',
  },
  correctiveCauseInfo3: {
    id: 'gofre.Documents.Rectify.correctiveCauseInfo3',
  },
  correctiveCauseInfo4: {
    id: 'gofre.Documents.Rectify.correctiveCauseInfo4',
  },
  correctiveCauseInfo5: {
    id: 'gofre.Documents.Rectify.correctiveCauseInfo5',
  },
  correctiveCauseTooltipInfo1: {
    id: 'gofre.Documents.Rectify.Replacement.correctiveCauseInfo1',
  },
  correctiveCauseTooltipInfo2: {
    id: 'gofre.Documents.Rectify.Replacement.correctiveCauseInfo2',
  },
  correctiveCauseTooltipInfo3: {
    id: 'gofre.Documents.Rectify.Replacement.correctiveCauseInfo3',
  },
  correctiveCauseTooltipInfo4: {
    id: 'gofre.Documents.Rectify.Replacement.correctiveCauseInfo4',
  },
  correctiveCauseTooltipInfo5: {
    id: 'gofre.Documents.Rectify.Replacement.correctiveCauseInfo5',
  },
  correctiveCauseLabel: {
    id: 'gofre.Documents.Rectify.correctiveCauseLabel',
  },
  correctiveCauseHelpText: {
    id: 'gofre.Documents.Rectify.correctiveCauseHelpText',
  },
  correctiveDetail: {
    id: 'gofre.Documents.Rectify.correctiveDetail',
  },
  correctiveDetailTicket: {
    id: 'gofre.Documents.Rectify.correctiveDetail.ticket',
  },
  correctiveReview: {
    id: 'gofre.Documents.Rectify.correctiveReview',
  },
  correctiveCta: {
    id: 'gofre.Documents.Rectify.correctiveCta',
  },
  correctiveCtaTicket: {
    id: 'gofre.Documents.Rectify.correctiveCta.ticket',
  },
  correctiveTotalPlaceholder: {
    id: 'gofre.common.label.total',
  },
  correctiveTotalPlaceholderTicket: {
    id: 'gofre.Documents.Rectify.correctiveTotalPlaceholder.ticket',
  },
  correctedInvoice: {
    id: 'gofre.Documents.Rectify.correctedInvoice',
  },
  correctiveTooltip: {
    id: 'gofre.Documents.Rectify.correctedTooltip',
  },
});

export default messsages;
