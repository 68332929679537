import { defineMessages } from 'react-intl';

import { DefineMessages } from 'modules/I18n/types';

const messages = defineMessages({
  title: { id: 'gofre.Documents.AddPaymentMethod.title' },
  titleForContact: { id: 'gofre.Documents.AddPaymentMethod.titleForContact' },
  subtitle: { id: 'gofre.Documents.AddPaymentMethod.subtitle' },
  confirm: { id: 'gofre.Documents.AddPaymentMethod.title' },
  cancel: { id: 'gofre.common.action.cancel' },
  bizum: { id: 'gofre.Documents.AddPaymentMethod.bizum' },
  cash: { id: 'gofre.Documents.AddPaymentMethod.cash' },
  phoneNumber: { id: 'gofre.Documents.AddPaymentMethod.phoneNumber' },
  direct_debit: { id: 'gofre.Documents.AddPaymentMethod.direct_debit' },
  transfer: { id: 'gofre.Documents.AddPaymentMethod.transfer' },
  bank_account_alias: {
    id: 'gofre.Documents.AddPaymentMethod.bank_account_alias',
  },
  bank_account_iban: {
    id: 'gofre.Documents.AddPaymentMethod.bank_account_iban',
  },
  bank_account_iban_contact: {
    id: 'gofre.Documents.AddPaymentMethod.bank_account_iban_contact',
  },
  bank_account_iban_placeholder: {
    id: 'gofre.Documents.AddPaymentMethod.bank_account_iban_placeholder',
  },
  bank_account_bic: { id: 'gofre.Documents.AddPaymentMethod.bank_account_bic' },
  cash_on_delivery: { id: 'gofre.Documents.AddPaymentMethod.cash_on_delivery' },
  other: { id: 'gofre.common.label.other' },
  other_label: { id: 'gofre.Documents.AddPaymentMethod.other_label' },
  transferHelp: { id: 'gofre.Documents.AddPaymentMethod.transfer.helpText' },
  format: { id: 'gofre.Documents.AddPaymentMethod.format' },
  futureDocuments: { id: 'gofre.Documents.AddPaymentMethod.futureDocuments' },
});

export const update: DefineMessages = defineMessages({
  title: { id: 'gofre.Documents.AddPaymentMethod.update.title' },
  titleForContact: {
    id: 'gofre.Documents.AddPaymentMethod.update.titleForContact',
  },
  confirm: { id: 'gofre.common.action.save' },
  cancel: { id: 'gofre.common.action.cancel' },
  transfer: { id: 'gofre.Documents.AddPaymentMethod.update.transfer' },
  bizum: { id: 'gofre.Documents.AddPaymentMethod.update.bizum' },
  direct_debit: { id: 'gofre.common.label.bank_transfer' },
  other: { id: 'gofre.Documents.AddPaymentMethod.update.other' },
  infoText: { id: 'gofre.Documents.AddPaymentMethod.update.info' },
});

export default messages;
