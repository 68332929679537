import _ from 'lodash';

import cleanVatNumber from 'utils/cleanVatNumber';

import { Contact } from '../../models';

const normalizePayload = (contact: Contact) => {
  return _.omit(
    { ...contact, vatNumber: cleanVatNumber(contact.vatNumber) },
    'additionalEmailsInput'
  );
};

export default normalizePayload;
