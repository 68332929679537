import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: { id: 'gofre.Documents.TestInvoiceModal.title' },
  subtitle: { id: 'gofre.Documents.TestInvoiceModal.subtitle' },
  text: { id: 'gofre.Documents.TestInvoiceModal.text' },
  confirmText: { id: 'gofre.Documents.TestInvoiceModal.confirmText' },
  cancel: { id: 'gofre.common.action.cancel' },
  checkBox: { id: 'gofre.ConfirmBaiSubmissionModal.label' },
});

export default messages;
