import { defineMessages, MessageDescriptor } from 'react-intl';

export const baseSendMessages = {
  attachment: { id: 'gofre.Quotes.detail.info.attachment' },
  bcc: { id: 'gofre.Documents.Send.cco' },
  cancel: { id: 'gofre.common.action.cancel' },
  cc: { id: 'gofre.Documents.Send.cc' },
  ccButton: { id: 'gofre.Documents.Send.cc.button' },
  config: { id: 'gofre.Documents.Send.config' },
  footer: { id: 'gofre.Documents.Send.Details.footer' },
  helpText: { id: 'gofre.Documents.Send.helpText' },
  requiredFields: { id: 'gofre.common.validation.mandatory_fields' },
  subject: { id: 'gofre.Documents.Send.subject' },
  to: { id: 'gofre.Documents.Send.to' },
  suggestionsHelpText: { id: 'gofre.Documents.Send.suggestionsHelpText' },
  attachFile: { id: 'gofre.Documents.Send.attachFile' },
  noService: { id: 'gofre.Documents.Send.no-service' },
};

export const messages = defineMessages({
  ...baseSendMessages,
  confirm: { id: 'gofre.Documents.Send.confirm' },
  content: { id: 'gofre.Documents.Send.content' },
  contentPlaceholder: { id: 'gofre.Documents.Send.content.placeholder' },
  date: { id: 'gofre.Documents.Send.Details.issueDate' },
  defaultContent: { id: 'gofre.Documents.Send.content.default-suggestions' },
  defaultSubject: { id: 'gofre.Documents.Send.subject.content.invoice' },
  detailsTitle: { id: 'gofre.invoices' },
  dueDate: { id: 'gofre.Documents.Send.Details.dueDate' },
  emailPlaceholder: { id: 'gofre.Documents.Send.email.placeholder' },
  footer: { id: 'gofre.Documents.Send.Details.footer' },
  header: { id: 'gofre.Documents.Send.Details.header' },
  includeDetails: { id: 'gofre.Documents.Send.details' },
  number: { id: 'gofre.Documents.Send.Details.number' },
  sendError: { id: 'gofre.Documents.Send.error' },
  sendSuccess: { id: 'gofre.Documents.Send.success' },
  subjectPlaceholder: { id: 'gofre.Documents.Send.subject.placeholder' },
  title: { id: 'gofre.Documents.Send.title' },
  saveDefaultsLabel: { id: 'gofre.Documents.Send.saveDefaults.default' },
});

export const quoteMessages = defineMessages({
  ...baseSendMessages,
  ccButton: { id: 'gofre.Documents.Send.cc.button' },
  config: { id: 'gofre.Documents.Send.config' },
  confirm: { id: 'gofre.Documents.Send.quoteConfirm' },
  content: { id: 'gofre.Documents.Send.quoteContent.placeholder' },
  contentPlaceholder: { id: 'gofre.Documents.Send.content.placeholder' },
  date: { id: 'gofre.Documents.Send.Details.quoteDate' },
  defaultContent: {
    id: 'gofre.Documents.Send.content.quotesContentDefault-suggestions',
  },
  defaultSubject: { id: 'gofre.Documents.Send.subject.content.quote' },
  detailsTitle: { id: 'gofre.Quotes' },
  dueDate: { id: 'gofre.Documents.Send.Details.dueDate' },
  emailPlaceholder: { id: 'gofre.Documents.Send.email.placeholder' },
  header: { id: 'gofre.Documents.Send.Details.quoteHeader' },
  includeDetails: { id: 'gofre.Documents.Send.quoteDetails' },
  number: { id: 'gofre.Documents.Send.Details.quoteNumber' },
  sendError: { id: 'gofre.Documents.Send.errorQuote' },
  sendSuccess: { id: 'gofre.Documents.Send.successQuote' },
  subjectPlaceholder: { id: 'gofre.Documents.Send.subject.placeholder' },
  title: { id: 'gofre.Documents.Send.quoteTitle' },
  saveDefaultsLabel: { id: 'gofre.Documents.Send.saveDefaults.quote' },
});

export const proformaMessages = defineMessages({
  ...baseSendMessages,
  confirm: { id: 'gofre.Documents.Send.proformaConfirm' },
  content: { id: 'gofre.Documents.Send.quoteContent.placeholder' },
  contentPlaceholder: { id: 'gofre.Documents.Send.content.placeholder' },
  date: { id: 'gofre.Documents.Send.Details.quoteDate' },
  defaultContent: {
    id: 'gofre.Documents.Send.content.proforma.default-suggestions',
  },
  defaultSubject: { id: 'gofre.Documents.Send.subject.content.proforma' },
  detailsTitle: { id: 'gofre.Proformas' },
  dueDate: { id: 'gofre.Documents.Send.Details.dueDate' },
  emailPlaceholder: { id: 'gofre.Documents.Send.email.placeholder' },
  header: { id: 'gofre.Documents.Send.Details.proforma' },
  includeDetails: { id: 'gofre.Documents.Send.details' },
  number: { id: 'gofre.Documents.Send.Details.proformaNumber' },
  sendError: { id: 'gofre.Documents.Send.errorProforma' },
  sendSuccess: { id: 'gofre.Documents.Send.successProforma' },
  subjectPlaceholder: { id: 'gofre.Documents.Send.subject.placeholder' },
  title: { id: 'gofre.Documents.Send.proformaTitle' },
  saveDefaultsLabel: { id: 'gofre.Documents.Send.saveDefaults.proforma' },
});

export const formErrors = defineMessages({
  recaptchaRequired: { id: 'gofre.Documents.Send.error.recaptcha' },
  emailInvalid: { id: 'gofre.Documents.Send.email.invalid' },
  emailRequired: { id: 'gofre.Documents.Send.email.required' },
  emailNotAddedError: { id: 'gofre.Documents.Send.error.email' },
});

export const suggestionsInfoModalMessages = defineMessages({
  title: { id: 'gofre.Documents.Send.suggestionsInfo.title' },
  titleMobile: { id: 'gofre.Documents.Send.suggestionsInfo.title.mobile' },
  content: { id: 'gofre.Documents.Send.suggestionsInfo.content' },
  contentNew: { id: 'gofre.Documents.Send.suggestionsInfo.contentNew' },
  contentReminders: {
    id: 'gofre.Documents.Send.suggestionsInfo.contentReminders',
  },
  summary: { id: 'gofre.Documents.Send.suggestionsInfo.summary' },
  button: { id: 'gofre.common.action.accept' },
});

export interface SendMessages {
  attachment: MessageDescriptor;
  bcc: MessageDescriptor;
  cancel: MessageDescriptor;
  cc: MessageDescriptor;
  ccButton: MessageDescriptor;
  config: MessageDescriptor;
  confirm: MessageDescriptor;
  content: MessageDescriptor;
  contentPlaceholder: MessageDescriptor;
  date: MessageDescriptor;
  defaultContent: MessageDescriptor;
  defaultSubject: MessageDescriptor;
  detailsTitle: MessageDescriptor;
  dueDate: MessageDescriptor;
  emailPlaceholder: MessageDescriptor;
  footer: MessageDescriptor;
  header: MessageDescriptor;
  helpText: MessageDescriptor;
  includeDetails: MessageDescriptor;
  number: MessageDescriptor;
  requiredFields: MessageDescriptor;
  sendError: MessageDescriptor;
  sendSuccess: MessageDescriptor;
  subject: MessageDescriptor;
  subjectPlaceholder: MessageDescriptor;
  title: MessageDescriptor;
  to: MessageDescriptor;
  saveDefaultsLabel: MessageDescriptor;
  suggestionsHelpText: MessageDescriptor;
  noService: MessageDescriptor;
}
