import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'gofre.PaymentsMethod.paymentsReminder.title' },
  titleMobile: { id: 'gofre.PaymentsMethod.paymentsReminder.titleMobile' },
  text: { id: 'gofre.PaymentsMethod.paymentsReminder.text' },
  addReminder: { id: 'gofre.PaymentsMethod.paymentsReminder.addReminder' },
  moreInfo: { id: 'gofre.PaymentsMethod.paymentsReminder.moreInfo' },
  tableHeader01: { id: 'gofre.PaymentsMethod.paymentsReminder.tableHeader01' },
  tableHeader02: { id: 'gofre.PaymentsMethod.paymentsReminder.tableHeader02' },
  tableHeader03: { id: 'gofre.common.action.edit' },
  tableHeader04: { id: 'gofre.PaymentsMethod.paymentsReminder.tableHeader04' },
  tableHeader05: { id: 'gofre.PaymentsMethod.paymentsReminder.tableHeader05' },
  editDesktop: { id: 'gofre.common.action.edit' },
  editMobile: { id: 'gofre.PaymentsMethod.paymentsReminder.edit.mobile' },
  active: { id: 'gofre.PaymentsMethod.paymentsReminder.active' },
  noActive: { id: 'gofre.PaymentsMethod.paymentsReminder.noactive' },
  daysBefore: { id: 'gofre.PaymentsMethod.paymentsReminder.daysBefore' },
  daysAfter: { id: 'gofre.PaymentsMethod.paymentsReminder.daysAfter' },
  expirationDate: {
    id: 'gofre.PaymentsMethod.paymentsReminder.expirationDate',
  },
  upgradeTitle: { id: 'gofre.PaymentsMethod.paymentsReminder.upgrade.title' },
  upgradeDescription: {
    id: 'gofre.PaymentsMethod.paymentsReminder.upgrade.description',
  },
  upgradeLink: { id: 'gofre.PaymentsMethod.paymentsReminder.upgrade.link' },
  edit: { id: 'gofre.common.action.edit' },
  delete: { id: 'gofre.PaymentsMethod.paymentsReminder.delete' },
  deleteModalTitle: {
    id: 'gofre.PaymentsMethod.paymentsReminder.delete.modal.title',
  },
  deleteModalSubtitle: {
    id: 'gofre.PaymentsMethod.paymentsReminder.delete.modal.subtitle',
  },
  deleteModalText: {
    id: 'gofre.PaymentsMethod.paymentsReminder.delete.modal.text',
  },
});
