import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  email: { id: 'gofre.common.billin.hola_billin_eu' },
  emailPlaceholder: {
    id: 'gofre.Subscription.Detail.cancel-subscription.email.placeholder',
  },
  phone: { id: 'gofre.common.billin.91_831_88_83' },
  phonePlaceholder: {
    id: 'gofre.Subscription.Detail.cancel-subscription.phone.placeholder',
  },
});
