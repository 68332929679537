import { defineMessages } from 'react-intl';

import { paymentMethodsMessages } from '../../messages';

const messages = defineMessages({
  operationDate: { id: 'gofre.common.label.date' },
  description: { id: 'gofre.common.label.description' },
  descriptionPlaceholder: {
    id: 'gofre.Payments.create.descriptionPlaceholder',
  },
  type: { id: 'gofre.Payments.create.type' },
  typeExpense: { id: 'gofre.Payments.create.type-expense' },
  typeIncome: { id: 'gofre.Payments.create.type-income' },
  amount: { id: 'gofre.Payments.create.amount' },
  method: { id: 'gofre.Payments.create.method' },
  methodPlaceholder: { id: 'gofre.common.action.select' },
  contact: { id: 'gofre.Payments.create.contact' },
  contactAdd: { id: 'gofre.Payments.create.contactAdd' },
  select: { id: 'gofre.common.action.select' },
  contactPlaceholder: { id: 'gofre.common.action.select' },
  minAmount: { id: 'gofre.Payments.create.minAmount' },
  ...paymentMethodsMessages,
});

export default messages;
