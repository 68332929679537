import { defineMessages } from 'react-intl';

const messages = defineMessages({
  success: { id: 'gofre.Bookkeeper.DeleteClient.success' },
  error: { id: 'gofre.Bookkeeper.DeleteClient.error' },
  title: { id: 'gofre.Bookkeeper.DeleteClient.title' },
  subtitle: { id: 'gofre.Bookkeeper.DeleteClient.subtitle' },
  text: { id: 'gofre.Bookkeeper.DeleteClient.text' },
  confirm: { id: 'gofre.common.action.continue' },
});

export default messages;
