import { defineMessages } from 'react-intl';

import { REVIEW_DATA_BAI, Steps } from './hooks/useFormStep';

export const dialogs = defineMessages({
  title: { id: 'gofre.Documents.Dialog.title' },
  subtitle: { id: 'gofre.Documents.Dialog.subtitle' },
  text: { id: 'gofre.Documents.Dialog.text' },
  cancel: { id: 'gofre.Documents.Dialog.cancel' },
  confirm: { id: 'gofre.Documents.Dialog.confirm' },
});

export const dialogsAlternative = defineMessages({
  subtitle: { id: 'gofre.Documents.Dialog.subtitle-alternative' },
  text: { id: 'gofre.Documents.Dialog.text-alternative' },
  cancel: { id: 'gofre.common.action.cancel' },
});

export const period = defineMessages({
  yearly: { id: 'gofre.Documents.period.yearly' },
  biannual: { id: 'gofre.Documents.period.biannual' },
  quarterly: { id: 'gofre.Documents.period.quarterly' },
  bimonthly: { id: 'gofre.Documents.period.bimonthly' },
  monthly: { id: 'gofre.Documents.period.monthly' },
  biweekly: { id: 'gofre.Documents.period.biweekly' },
  weekly: { id: 'gofre.Documents.period.weekly' },
});

export const create = defineMessages({
  back: { id: 'gofre.Documents.Header.back' },
  concepts: { id: 'gofre.Documents.Form.StepTitle.concepts' },
  contact: { id: 'gofre.Documents.Form.StepTitle.contact' },
  continueButton: { id: 'gofre.common.action.continue' },
  goBackButton: { id: 'gofre.common.action.back' },
  review: { id: 'gofre.Documents.Form.StepTitle.review' },
  title: { id: 'gofre.Documents.Header.title' },
  titleIsCorrective: { id: 'gofre.Documents.Header.titleIsCorrective' },
  editTitle: { id: 'gofre.Documents.Header.title.update' },
  titleRecurrent: { id: 'gofre.Documents.Header.title.recurrent' },
  totals: { id: 'gofre.common.label.total' },
  success: { id: 'gofre.Documents.Create.Modal.sucess' },
  recurrentSuccess: { id: 'gofre.Documents.Create.recurrent.sucess' },
  error: { id: 'gofre.Documents.Create.Modal.error' },
  createLimitReachedTitle: {
    id: 'gofre.Documents.Create.Modal.error.limitReached.title',
  },
  createLimitReachedText: {
    id: 'gofre.Documents.Create.Modal.error.limitReached.text',
  },
  duplicateCodeError: {
    id: 'gofre.Documents.Create.Modal.duplicate-code-error',
  },
});

export const formErrors = defineMessages({
  codeMaxLimit: { id: 'gofre.Documents.Form.codeMaxLimit' },
  dueDateBeforeCreationDate: {
    id: 'gofre.Documents.Form.dueDateBeforeCreationDate',
  },
  postalCodeNumeric: { id: 'gofre.Documents.Form.postalCodeNumeric' },
  vatNumberInvalid: { id: 'gofre.common.validation.nif_invalid' },
  autoSendError: { id: 'gofre.Documents.Form.auto-sending-not-configured' },
  notZero: { id: 'gofre.Documents.Form.notZero' },
  taxRegimeInvalidNif: { id: 'gofre.Documents.Form.taxRegimeInvalidNif' },
  operationDateTimeTooOld: {
    id: 'gofre.Documents.Form.operationDateTimeTooOld',
  },
  operationDateTimeInTheFuture: {
    id: 'gofre.Documents.Form.operationDateTimeInTheFuture',
  },
  operationDateTimeNotTodayOrPast: {
    id: 'gofre.Documents.Form.operationDateTimeNotTodayOrPast',
  },
});

export const stepsMessages = defineMessages({
  [Steps.DOCUMENT_DATA]: {
    id: `gofre.Documents.steps.${Steps.DOCUMENT_DATA}`,
  },
  [Steps.REVIEW_DATA]: {
    id: `gofre.Documents.steps.${Steps.REVIEW_DATA}`,
  },
  [REVIEW_DATA_BAI]: {
    id: `gofre.Documents.steps.${REVIEW_DATA_BAI}`,
  },
});

export const dueMessages = defineMessages({
  none: {
    id: 'gofre.Documents.due.none',
  },
  weekly: {
    id: 'gofre.Documents.due.weekly',
  },
  biweekly: {
    id: 'gofre.Documents.due.biweekly',
  },
  monthly: {
    id: 'gofre.Documents.due.monthly',
  },
  bimonthly: {
    id: 'gofre.Documents.due.bimonthly',
  },
  quarterly: {
    id: 'gofre.Documents.due.quarterly',
  },
});

export const periodMessages = defineMessages({
  weekly: {
    id: 'gofre.Documents.period.weekly',
  },
  biweekly: {
    id: 'gofre.Documents.period.biweekly',
  },
  monthly: {
    id: 'gofre.Documents.period.monthly',
  },
  bimonthly: {
    id: 'gofre.Documents.period.bimonthly',
  },
  quarterly: {
    id: 'gofre.Documents.period.quarterly',
  },
  biannual: {
    id: 'gofre.Documents.period.biannual',
  },
  yearly: {
    id: 'gofre.Documents.period.yearly',
  },
});

export const tracking = defineMessages({
  create: { id: 'gofre.Documents.Tracking.status.create' },
  create_corrective: {
    id: 'gofre.Documents.Tracking.status.createCorrective',
  },
  create_draft: { id: 'gofre.Documents.Tracking.status.create_draft' },
  corrected: { id: 'gofre.Documents.Tracking.status.corrected' },
  deleted: { id: 'gofre.Documents.Tracking.status.deleted' },
  edited: { id: 'gofre.Documents.Tracking.status.edited' },
  failToContact: { id: 'gofre.Documents.Tracking.status.fail-to-contact' },
  issued: { id: 'gofre.common.label.issued' },
  mark_as_corrected: {
    id: 'gofre.Documents.Tracking.status.mark_as_corrected',
  },
  mark_as_paid: { id: 'gofre.Documents.Tracking.status.mark_as_paid' },
  mark_as_unpaid: { id: 'gofre.Documents.Tracking.status.mark_as_unpaid' },
  rejected: { id: 'gofre.Documents.Tracking.status.rejected' },
  sent: { id: 'gofre.Documents.Tracking.status.sent' },
  read: { id: 'gofre.Documents.Tracking.status.read' },
  received: { id: 'gofre.Documents.Tracking.status.received' },
  spam: { id: 'gofre.Documents.Tracking.status.spam' },
  blocked: { id: 'gofre.Documents.Tracking.status.blocked' },
  submitted: { id: 'gofre.Documents.Tracking.status.digital.submitted' },
  void: { id: 'gofre.Documents.Tracking.status.digital.void' },
  voidDigital: { id: 'gofre.Documents.Tracking.status.digital.void-presented' },
  addPaymentToDocument: { id: 'gofre.Documents.Tracking.status.add_payment' },
  deletePaymentFromDocument: {
    id: 'gofre.Documents.Tracking.status.delete_payment',
  },
  updatePaymentFromDocument: {
    id: 'gofre.Documents.Tracking.status.update_payment',
  },
});

export const quotesTracking = defineMessages({
  sent: { id: 'gofre.Quotes.Tracking.status.sent' },
  read: { id: 'gofre.Quotes.Tracking.status.read' },
  received: { id: 'gofre.Quotes.Tracking.status.received' },
  spam: { id: 'gofre.Quotes.Tracking.status.spam' },
  blocked: { id: 'gofre.Quotes.Tracking.status.blocked' },
});

export const sentStatus = defineMessages({
  sent: { id: 'gofre.Documents.sentStatus.sent' },
  received: { id: 'gofre.Documents.sentStatus.received' },
  read: { id: 'gofre.Documents.sentStatus.read' },
  spam: { id: 'gofre.Documents.sentStatus.spam' },
  blocked: { id: 'gofre.Documents.sentStatus.blocked' },
  title: { id: 'gofre.Documents.sentStatus.title' },
});

export default defineMessages({
  clientData: {
    id: 'gofre.Documents.DocumentDataStep.Form.client-data-bai',
  },
  addClient: {
    id: 'gofre.Documents.DocumentDataStep.add-client-bai',
  },
  fiscalName: {
    id: 'gofre.Documents.DocumentDataStep.Form.fiscal-name-bai',
  },
  fiscalNamePlaceholder: {
    id: 'gofre.Documents.DocumentDataStep.Form.fiscal-name-placeholder-bai',
  },
  vatNumber: { id: 'gofre.Documents.DocumentDataStep.Form.vat-number-bai' },
  postalAddress: {
    id: 'gofre.Documents.DocumentDataStep.Form.postal-address-bai',
  },
  postalAddressPlaceholder: {
    id: 'gofre.Documents.DocumentDataStep.Form.postal-address-placeholder-bai',
  },
  city: { id: 'gofre.Documents.DocumentDataStep.Form.city-bai' },
  cityPlaceholder: {
    id: 'gofre.Documents.DocumentDataStep.Form.city-placeholder-bai',
  },
  postalCode: { id: 'gofre.Documents.DocumentDataStep.Form.postalCode-bai' },
  postalCodePlaceholder: {
    id: 'gofre.Documents.DocumentDataStep.Form.postalCode-placeholder-bai',
  },
  province: { id: 'gofre.Documents.DocumentDataStep.Form.province-bai' },
  provincePlaceholder: {
    id: 'gofre.Documents.DocumentDataStep.Form.provincePlaceholder-bai',
  },
  country: { id: 'gofre.Documents.DocumentDataStep.Form.country-bai' },
  countryPlaceholder: {
    id: 'gofre.Documents.DocumentDataStep.Form.country.placeholder-bai',
  },
  documentType: {
    id: 'gofre.Documents.DocumentDataStep.Form.documentType-bai',
  },
  nif: { id: 'gofre.Documents.DocumentDataStep.Form.nif-bai' },
  vatNumberTypeNif: {
    id: 'gofre.Documents.DocumentDataStep.Form.vatNumberType.Nif',
  },
  vatNumberTypeNifIva: {
    id: 'gofre.Documents.DocumentDataStep.Form.vatNumberType.NifIva',
  },
  vatNumberTypePassport: {
    id: 'gofre.Documents.DocumentDataStep.Form.vatNumberType.Passport',
  },
  vatNumberTypeCertificate: {
    id: 'gofre.Documents.DocumentDataStep.Form.vatNumberType.Certificate',
  },
  vatNumberTypeOrigin: {
    id: 'gofre.Documents.DocumentDataStep.Form.vatNumberType.Origin',
  },
  vatNumberTypeOther: {
    id: 'gofre.common.label.other',
  },
  provinceEstate: {
    id: 'gofre.Documents.DocumentDataStep.Form.provinceEstate',
  },
});
