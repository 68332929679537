import omit from 'lodash/omit';

import { Contact, emptyContact } from 'modules/Contacts/models';
import cleanVatNumber from 'utils/cleanVatNumber';

const normalizePayload = (contact: Contact = emptyContact) => {
  const payload = { ...contact, vatNumber: cleanVatNumber(contact.vatNumber) };
  return omit(
    payload,
    'readyToInvoice',
    'usages',
    'lastUsageAt',
    'lastDocumentIssuedDate',
    'paymentMethods',
    'additionalEmailsInput'
  );
};

export default normalizePayload;
