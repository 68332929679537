import { isEmpty, sortBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Contact } from 'modules/Contacts/models';
import { DocumentDefaultValues } from 'modules/DefaultValues/models/document-default-values';
import { createAdditionalExpense } from 'modules/Documents/helpers/default-additionalExpense';
import {
  DocumentPeriodType,
  Document,
  DocumentStatus,
} from 'modules/Documents/models/document';
import { PersonType } from 'modules/Documents/models/personType';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';

import {
  injectContactWithPaymentMethods,
  processPaymentMethodsFromDocument,
} from './helpers';
import processConcepts from './processConcepts';

function issueFromQuoteValues({
  document,
  initialCode,
  initialSerialCode,
  initialSerieValue,
  paymentMethods,
  savedDefaultValues,
  updatedContact,
}: {
  document: Document;
  initialSerieValue: string | null;
  initialCode: number;
  initialSerialCode: string;
  contact: Contact;
  paymentMethods?: CreatedPaymentMethod[];
  savedDefaultValues?: DocumentDefaultValues | null;
  updatedContact?: Contact;
}) {
  const showComments =
    !!document.comments || (savedDefaultValues?.showComments ?? true);
  const hasRetentions =
    !!document.totals?.retentionPercentage ||
    !!savedDefaultValues?.retentionPercentage;

  const documentContactWithUpdatedPaymentMethods =
    injectContactWithPaymentMethods({
      updatedContact,
      documentContact: document.contact,
      documentPaymentMethods: document.paymentMethodsAccepted,
    });

  return {
    hasAdditionalExpenses: !isEmpty(document.additionalExpenses),
    additionalExpenses: !isEmpty(document.additionalExpenses)
      ? document.additionalExpenses
      : [createAdditionalExpense()],
    creationDate: document.issuedDate
      ? new Date(document.issuedDate)
      : new Date(),
    dueDate: document.dueDate ? new Date(document.dueDate) : undefined,
    code: initialCode,
    initDateTime: document.issuedDateTime
      ? new Date(document.issuedDateTime)
      : new Date(),
    newDocumentPeriod: DocumentPeriodType.MONTHLY,
    dueDocumentPeriod: DocumentPeriodType.NONE,
    concepts: sortBy(processConcepts(document.concepts), 'groupName'),
    showComments,
    comments: showComments ? document?.comments : savedDefaultValues?.comments,
    contact: documentContactWithUpdatedPaymentMethods,
    hasRetentions,
    hasSalesEqualizationTax: document.hasSalesEqualizationTax,
    reference: document.reference || '',
    retentionPercentage: document.totals?.retentionPercentage || 15,
    hasPaidAmount: !!document.totals?.paidAmount,
    paidAmount: document.totals?.paidAmount || 0,
    serialCode: initialSerialCode,
    serieId: initialSerieValue,
    status: DocumentStatus.ISSUED,
    showLinesWithoutTaxes: savedDefaultValues?.showLinesWithoutTaxes,
    // Bai
    operationDescription: savedDefaultValues?.operationDescription ?? '',
    taxRegime: savedDefaultValues?.taxRegime,
    naturalPerson: savedDefaultValues?.naturalPerson,
    naturalPersonType: savedDefaultValues?.naturalPerson
      ? PersonType.NATURAL
      : PersonType.LEGAL,
    epigraphIAE: savedDefaultValues?.epigraphIAE,
    lastEpigraphsIAE: savedDefaultValues?.lastEpigraphsIAE ?? [],
    chargingCriterionIRPF: savedDefaultValues?.chargingCriterionIRPF,
    isDefaultDraft: false,
    templateId: document?.templateId,
    currency: document.currency,
    paymentMethods: processPaymentMethodsFromDocument({
      documentMethods: document.paymentMethodsAccepted,
      defaultMethods: paymentMethods,
      preserveContactMethods: true,
    }),
    showProductReference: savedDefaultValues?.showProductReference,
    requestId: uuidv4(),
  };
}

export default issueFromQuoteValues;
